import React, {useState} from 'react';
import {useLocation} from '@reach/router';
import {Adjuster, StateProps, Vehicle, VehicleIncident} from '../components/common/Summary/types';
import {AppFormContainer} from '../components/common/Containers/Containers';
import {
  QuickEstimate,
  QuickEstimateSchema,
  QuickEstimates,
  QuickEstimateInstance,
} from '../components/auto/QuickEstimate/QuickEstimate';
import {trackEvent} from '../services/analytics';
import {appraiserAssignment, fraudModel, stpModel, STPModelResponse, stpUpdate} from '../services';
import {Snowplow} from './utils/snowplow';
import {useUserAtomState} from '../atoms';
import {AutoIntegrationFlags, CustomerTypes} from '../commonTypes';
import {BackButtonBehavior} from '../components/common/BackButtonBehavior/BackButtonBehavior';
import {navigateDefaultReplace} from './utils';
import {SUMMARY_PAGE_ROUTE, ROOT_ROUTE, COPART_ROUTE, STAFF_APPRAISER_ROUTE} from '../routes/paths';
import {mapData} from '../utils/dataMapper';
import {
  filterVehiclesByEligibility,
  getVehicleIncidents,
  isIntegrationEligible,
} from '../utils/utils';
import {useFeatureFlags} from '../components/common/Providers/Providers';

export const QuickEstimatePage = () => {
  const [userAtomState] = useUserAtomState();
  const isAssociate = userAtomState.gettingStarted?.customerType === CustomerTypes.Associate;
  const isAssociateOrAgent =
    userAtomState.gettingStarted?.customerType === CustomerTypes.Associate ||
    userAtomState.gettingStarted?.customerType === CustomerTypes.SaAgent;

  const {featureFlags} = useFeatureFlags();
  const draftResponse = userAtomState.draftClaimResponse;
  const location = useLocation();
  const successState = location.state as StateProps;
  const userPersona = successState?.customerType || '';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const vehicleIncidents: Vehicle[] = getVehicleIncidents(successState) || [];
  const nonEligibleVehicles: VehicleIncident[] = filterVehiclesByEligibility(
    vehicleIncidents,
    AutoIntegrationFlags.QuickEstimateEligible,
    true
  );
  const eligibleVehicles: VehicleIncident[] = filterVehiclesByEligibility(
    vehicleIncidents,
    AutoIntegrationFlags.QuickEstimateEligible
  );
  const staffAppraiserOnlyVehicles: VehicleIncident[] = filterVehiclesByEligibility(
    nonEligibleVehicles,
    AutoIntegrationFlags.StaffAppraiserEligible
  );

  const onSubmit = async (formData: QuickEstimates) => {
    let declinedQuickEstimateVehicles: VehicleIncident[] = [];
    let quickEstimateVehicles: VehicleIncident[] = [];
    let acceptedQuickEstimateVehicles: boolean = false;
    let {quickEstimates} = formData;

    quickEstimates.forEach((vehicle: QuickEstimateInstance) => {
      let quickEstimateRequested = vehicle.quickEstimateRequested;
      const vehicleData =
        eligibleVehicles.find(
          (incidentVehicle: VehicleIncident) => incidentVehicle.publicID === vehicle.publicID
        ) || {};
      quickEstimateVehicles.push({...vehicleData, quickEstimateRequested});

      if (quickEstimateRequested === 'no') {
        declinedQuickEstimateVehicles.push({...vehicleData, quickEstimateRequested});
        trackEvent({category: 'Quick Estimate', label: 'Opt Out'});
      } else if (quickEstimateRequested === 'yes' && !acceptedQuickEstimateVehicles) {
        acceptedQuickEstimateVehicles = true;
      }

      Snowplow.track.quickEstimate({
        quickEstimateAccepted: quickEstimateRequested,
        claimNumber: successState.claimNumber,
        persona: userPersona,
        logrocketurl: 'N/A',
      });
    });

    const copartEligible =
      isIntegrationEligible(vehicleIncidents, AutoIntegrationFlags.CopartEligible) &&
      featureFlags?.FF_DCX_2155 &&
      userPersona === CustomerTypes.Associate;
    const stpEligible =
      isIntegrationEligible(vehicleIncidents, AutoIntegrationFlags.STPModelEligible) &&
      featureFlags?.FF_DCX_2564;
    quickEstimates = formData.quickEstimates.concat(
      staffAppraiserOnlyVehicles as QuickEstimateInstance[]
    );
    let vehiclesToShowStaff: VehicleIncident[] = filterVehiclesByEligibility(
      declinedQuickEstimateVehicles,
      AutoIntegrationFlags.StaffAppraiserEligible
    );
    vehiclesToShowStaff = vehiclesToShowStaff.concat(staffAppraiserOnlyVehicles);
    let navToStaffScreen: boolean = !!vehiclesToShowStaff.length;
    let stpResult: STPModelResponse | undefined;
    let adjusterDetails: Adjuster | undefined = successState.adjusterDetails;
    setIsSubmitting(true);
    let isStpUpdateCallFailed = successState.isStpUpdateCallFailed;

    try {
      const response = await appraiserAssignment({data: successState, quickEstimates});
      if (response.data.hasErrors) {
        throw new Error(`${response.data.errors?.[0]}:${response.data.errors?.[1]}`);
      }
      vehiclesToShowStaff = vehiclesToShowStaff.filter(
        vehicle =>
          !!response.data.data.appraiserAssigned.filter(
            (assignedVehicle: {publicID: string; appraiserSelection: string}) =>
              assignedVehicle.publicID === vehicle.publicID
          ).length
      );
      navToStaffScreen = !!vehiclesToShowStaff.length;
    } catch (error) {
      vehiclesToShowStaff = [];
      navToStaffScreen = false;
    }

    if (stpEligible && (acceptedQuickEstimateVehicles || vehiclesToShowStaff.length)) {
      try {
        const claimNumber = successState.claimNumber;
        const fraudResponse = await fraudModel({claimNumber});
        const postFraudCheck =
          fraudResponse?.data?.data &&
          typeof fraudResponse.data.data !== 'boolean' &&
          fraudResponse.data.data.postFraudCheck;

        if (postFraudCheck) {
          try {
            const stpResponse = await stpModel({
              data: successState,
              postFraudCheck,
              quickEstimates,
            });
            stpResult = stpResponse?.data?.data;
            const publicID = successState.publicID;
            const stpFlag = stpResult?.AutoSTPClaimProcessEligibilityIndicator === 'Eligible';
            const finalBusinessRuleScore = postFraudCheck.final_business_rule_score;
            const finalModelScore = postFraudCheck.final_model_score;
            const finalResult = postFraudCheck.final_result;
            if (stpFlag) {
              try {
                const stpUpdateResponse = await stpUpdate({
                  publicID,
                  claimNumber,
                  stpFlag,
                  finalBusinessRuleScore,
                  finalModelScore,
                  finalResult,
                });
                const responseAdjusterDetails =
                  stpUpdateResponse?.data?.data?.result?.adjusterDetails;
                adjusterDetails = responseAdjusterDetails || adjusterDetails;
                isStpUpdateCallFailed = false;
              } catch (error) {
                isStpUpdateCallFailed = true;
              }
            }
          } catch (error) {}
        }
      } catch (error) {}
    }

    let url: string = '';

    if (navToStaffScreen) {
      url = STAFF_APPRAISER_ROUTE;
    } else if (copartEligible) {
      url = COPART_ROUTE;
    } else {
      url = SUMMARY_PAGE_ROUTE;
    }

    const navToSummary = url === SUMMARY_PAGE_ROUTE;
    const baseNavState = {
      ...successState,
      quickEstimateVehicles,
      vehiclesToShowStaff,
      stpResult,
      isStpUpdateCallFailed,
      adjusterDetails,
    };

    navigateDefaultReplace(url, {
      state: navToSummary
        ? mapData(baseNavState, successState.formData, draftResponse)
        : baseNavState,
    });
  };

  return (
    <BackButtonBehavior shouldDisplayAlert route={ROOT_ROUTE}>
      <AppFormContainer
        submitControl={onSubmit}
        submitButtonLabel="Complete My Claim"
        validationSchema={QuickEstimateSchema}
        isSubmitting={isSubmitting}
        hideBreadcrumbs={!isAssociateOrAgent}
        hideAddNoteButton
        hideCoveragesButton={!isAssociate}
      >
        <>
          {eligibleVehicles?.map((vehicle: VehicleIncident, index: number) => (
            <QuickEstimate
              userPersona={userPersona}
              reporterInfo={successState.formData?.gettingStarted}
              vehicle={vehicle}
              index={index}
            />
          ))}
        </>
      </AppFormContainer>
    </BackButtonBehavior>
  );
};
