import {Button} from '@material-ui/core';
import React from 'react';
import {useBreadcrumbContext} from '../../../hooks';
import {trackEvent} from '../../../services/analytics';
import {WAYPOINTS} from '../RegisterBreadcrumb/RegisterBreadcrumb';

export interface BreadcrumbButtonProps {
  buttonWidth: string;
  waypointName: string;
}

export const BreadcrumbButton = ({buttonWidth, waypointName}: BreadcrumbButtonProps) => {
  const breadcrumbContext = useBreadcrumbContext();
  const displayName = breadcrumbContext?.breadcrumbs?.[waypointName]?.displayName;
  const isDisabled = !displayName;

  const scrollTo = (waypointName: string) => {
    const waypoint = document.getElementById(waypointName);

    window.scrollTo(0, (waypoint?.offsetTop || 0) - 50);

    setTimeout(() => {
      breadcrumbContext.updateBreadcrumbState(waypointName, true);
    }, 100);
  };

  const onClickHandler = (waypointName: string) => {
    scrollTo(waypointName);

    trackEvent({category: 'Navigation Selected', label: WAYPOINTS[waypointName].displayName});
  };

  return (
    <Button
      className={`breadcrumb ${buttonWidth} ${waypointName}${isDisabled ? ' --inactive' : ''}`}
      id={waypointName.toLocaleLowerCase()}
      data-bc-id={waypointName}
      data-testID={waypointName}
      disabled={isDisabled}
      onClick={() => onClickHandler(waypointName)}
    >
      {displayName || WAYPOINTS[waypointName].displayName}
    </Button>
  );
};
