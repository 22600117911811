import {
  useRecoilState,
  SetterOrUpdater,
  DefaultValue,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
} from 'recoil';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  ClaimLobs,
  ContactRoles,
  ContactTypes,
  CustomerTypes,
  Lobs,
  PhoneTypes,
} from '../commonTypes';
import {ModalTypes} from '../components/common/Providers/ModalController';
import {Contact, Vehicle} from '../components/common/Summary/types';
import {
  formatPhoneToMatchValidation,
  getDefaultValuesFromToken,
  getDisplayName,
  hasContactRoles,
  hasSomeContactRoles,
  authUserMatchesPolicyContact,
  makePersonaCheck,
} from '../utils/utils';
import {AtomFactory} from './base';
import {DraftClaimResponse} from '../components/common/Providers/Providers';
import {
  FnolFormContact,
  ContactSections,
  FormContactPhone,
  ContactInfoModalProps,
  FormContactSnapshot,
} from '../components/common/ContactInfo/types';

export enum AtomKeys {
  UserAtomState = 'userAtomState',
  GettingStartedAtomState = 'gettingStartedAtomState',
  PolicyContactAtomState = 'policyContactAtomState',
  VehicleContactAtomState = 'vehicleContactAtomState',
  ReporterAtomState = 'reporterAtomState',
  ModalAtomState = 'modalAtomState',
  ContactListAtomState = 'contactListAtomState',
  FormContactAtomState = 'formContactAtomState',
  CatastropheAtomState = 'catastropheAtomState',
  AdditionalNotesAtomState = 'additionalNotesAtomState',
  AddNotesModalAtomState = 'addNotesModalAtomState',
  NoticeOnlyAtomState = 'noticeOnlyAtomState',
  VehicleOptionsAtomState = 'vehicleOptionsAtomState',
}

export enum SelectorKeys {
  FirstContactOccurrenceSelector = 'firstContactOccurenceSelector',
  PolicyContactSelector = 'policyContactSelector',
}

/* USER ATOM STATE */
export interface GettingStartedState {
  customerType: string;
  lob: Lobs;
  isAuthInsured?: boolean;
}

export interface UserAtomStateValue {
  gettingStarted?: GettingStartedState;
  policyNumber?: string;
  lossDate?: number | Date;
  lossTime?: number | Date;
  lossTimezone?: string;
  draftClaimResponse?: any;
  uuid?: string;
  // logrocketurl?: string;
  commercialAutoPage?: boolean;
}

const sessionStorageEffect =
  (key: string) =>
  ({setSelf, onSet}: {setSelf: any; onSet: any}) => {
    const gettingStarted = sessionStorage?.getItem(key);

    if (gettingStarted !== null && gettingStarted !== undefined) {
      setSelf({...userAtomState, gettingStarted: JSON.parse(gettingStarted as unknown as string)});
    }

    onSet((newValue: UserAtomStateValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage?.removeItem(key);
      } else {
        sessionStorage?.setItem(key, JSON.stringify(newValue.gettingStarted));
      }
    });
  };

export const userAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.UserAtomState,
  defaultValue: {} as UserAtomStateValue,
  effects: [sessionStorageEffect('gettingStarted')],
});

export const useUserAtomState = (): [UserAtomStateValue, SetterOrUpdater<UserAtomStateValue>] =>
  useRecoilState(userAtomState);

export const useSetUserAtomState = (): SetterOrUpdater<UserAtomStateValue> =>
  useSetRecoilState(userAtomState);

export const useResetUserAtomState = () => {
  const setUserAtomState = useSetUserAtomState();
  const resetUserAtomState = () => {
    setUserAtomState((state: UserAtomStateValue) => {
      return {
        ...state,
        policyNumber: '',
        lossDate: undefined,
        lossTime: undefined,
        uuid: '',
        draftClaimResponse: {},
      };
    });
  };

  return resetUserAtomState;
};
/* END USER ATOM STATE */

/* POLICY CONTACT ATOM STATE */
export enum ContactDropdownLocations {
  Reporter = 0,
  InsuredMainContact = 1,
  Owner = 2,
  Driver = 3,
  Passenger = 4,
}

export const CONTACT_DROPDOWN_LOCATIONS = [
  ContactDropdownLocations.Reporter,
  ContactDropdownLocations.InsuredMainContact,
  ContactDropdownLocations.Owner,
  ContactDropdownLocations.Driver,
  ContactDropdownLocations.Passenger,
];

interface ContactOccurrence {
  location: ContactDropdownLocations;
  index: number;
}

const NON_POLICY_IDS = ['other', 'unknown', 'nodriver'];

export const policyContactAtomState = AtomFactory.createAtomFamily({
  uniqueId: AtomKeys.PolicyContactAtomState,
  defaultValue: [] as ContactOccurrence[],
});

export const firstContactOccurenceSelector = selectorFamily({
  key: SelectorKeys.FirstContactOccurrenceSelector,
  get:
    (id: string) =>
    ({get}) => {
      const occurrences: ContactOccurrence[] = get(policyContactAtomState(id));
      const firstLocation =
        CONTACT_DROPDOWN_LOCATIONS[
          Math.min(...(occurrences.map((o: ContactOccurrence) => o.location) as number[]))
        ];
      const occurrencesAtFL = occurrences.filter(
        (o: ContactOccurrence) => o.location === firstLocation
      );
      const lowestIndex = Math.min(...occurrencesAtFL.map((o: ContactOccurrence) => o.index));
      const firstOccurrence = occurrencesAtFL.find(
        (o: ContactOccurrence) => o.index === lowestIndex
      );

      return firstOccurrence;
    },
});

export const usePolicyContactAtomState = (
  id: string = '',
  location: ContactDropdownLocations,
  index: number = 0
) => {
  const occurrence: ContactOccurrence = {location, index};
  const setPolicyContactAtomState = useSetRecoilState(policyContactAtomState(id));
  const firstOccurrence = useRecoilValue(firstContactOccurenceSelector(id));
  const isPolicyContact = id && !NON_POLICY_IDS.includes(id);
  const showContactInfo =
    occurrence.location === firstOccurrence?.location &&
    occurrence.index === firstOccurrence?.index;

  const addOccurrence = () => {
    if (isPolicyContact) {
      setPolicyContactAtomState((state: ContactOccurrence[]) => [...state, occurrence]);
    }
  };

  const removeOccurrence = () => {
    if (isPolicyContact) {
      setPolicyContactAtomState((state: ContactOccurrence[]) => {
        let occurrences: ContactOccurrence[] = [...state];
        const index = occurrences.findIndex(
          (o: ContactOccurrence) =>
            o.location === occurrence.location && o.index === occurrence.index
        );

        if (index !== -1) {
          occurrences.splice(index, 1);
        }

        return occurrences;
      });
    }
  };

  return {addOccurrence, removeOccurrence, showContactInfo};
};

export const useSetPolicyContactAtomState = (
  id: string = '',
  location: ContactDropdownLocations,
  index: number = 0
) => {
  const occurrence: ContactOccurrence = {location, index};
  const setPolicyContactAtomState = useSetRecoilState(policyContactAtomState(id));
  const isPolicyContact = id && !NON_POLICY_IDS.includes(id);

  const removeOccurrence = () => {
    if (isPolicyContact) {
      setPolicyContactAtomState((state: ContactOccurrence[]) => {
        let occurrences: ContactOccurrence[] = [...state];
        const index = occurrences.findIndex(
          (o: ContactOccurrence) =>
            o.location === occurrence.location && o.index === occurrence.index
        );

        if (index !== -1) {
          occurrences.splice(index, 1);
        }

        return occurrences;
      });
    }
  };

  return removeOccurrence;
};
/* END POLICY CONTACT ATOM STATE */

/* VEHICLE CONTACT ATOM STATE */
export const vehicleContactAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.VehicleContactAtomState,
  defaultValue: [] as Contact[],
});

export const useVehicleContactAtomState = (): [Contact[], SetterOrUpdater<Contact[]>] =>
  useRecoilState(vehicleContactAtomState);

export const useSetVehicleContactAtomState = (): SetterOrUpdater<Contact[]> =>
  useSetRecoilState(vehicleContactAtomState);
/* END VEHICLE CONTACT ATOM STATE */

/* REPORTER ATOM STATE */
export interface ReporterData {
  contact?: Contact;
  howReported?: string;
  isPolicyContact: boolean;
}

export interface ReporterMetaData {
  isInsured: boolean;
  isNotAgentLienholder: boolean;
}

export const DEFAULT_REPORTER_ATOM_VALUE = {
  howReported: '',
  contact: undefined,
  isPolicyContact: false,
};

export const DEFAULT_REPORTER_META_DATA = {
  isInsured: false,
  isNotAgentLienholder: false,
};

export const reporterAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.ReporterAtomState,
  defaultValue: DEFAULT_REPORTER_ATOM_VALUE as ReporterData,
});

export const useSetReporterAtomState = (): SetterOrUpdater<ReporterData> =>
  useSetRecoilState(reporterAtomState);

export const useGetReporterAtomState = (): ReporterData & ReporterMetaData => {
  const data = useRecoilValue(reporterAtomState);

  if (data) {
    const isInsured = hasContactRoles(data.contact as Contact, [ContactRoles.Insured]);
    const isNotAgentLienholder = !hasSomeContactRoles(data.contact, [
      ContactRoles.Agent,
      ContactRoles.Lienholder,
    ]);

    return {
      ...data,
      isInsured,
      isNotAgentLienholder,
    };
  } else {
    return {...DEFAULT_REPORTER_ATOM_VALUE, ...DEFAULT_REPORTER_META_DATA};
  }
};
/* END REPORTER ATOM STATE */

/* MODAL ATOM STATE */
export interface ModalAtomState {
  useBackdrop: boolean;
  open: boolean;
  props: any;
}

export const modalAtomState = AtomFactory.createAtomFamily({
  uniqueId: AtomKeys.ModalAtomState,
  defaultValue: {open: false} as ModalAtomState,
});

export const useModalAtomState = (id: ModalTypes) => useRecoilState(modalAtomState(id));

export const useModalAtomValue = (id: ModalTypes) => useRecoilValue(modalAtomState(id));

export const useOpenOrCloseModal = <T>(id: ModalTypes) => {
  const setModalAtomState = useSetRecoilState(modalAtomState(id));

  const openModal = (props: T, useBackdrop: boolean = true) => {
    setModalAtomState((state: ModalAtomState) => {
      return {...state, open: true, useBackdrop, props};
    });
  };

  const closeModal = () => {
    setModalAtomState((state: ModalAtomState) => {
      return {...state, open: false};
    });
  };

  return {openModal, closeModal};
};

export const useOpenOrCloseContactInfoModal = () => {
  const {openModal, closeModal} = useOpenOrCloseModal<ContactInfoModalProps>(
    ModalTypes.ContactInfoModal
  );

  return {openContactInfoModal: openModal, closeContactInfoModal: closeModal};
};
/* END MODAL ATOM STATE */

/* CONTACT LIST ATOM STATE */
export const contactListAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.ContactListAtomState,
  defaultValue: [] as FormContactSnapshot[],
});

export const useContactListAtomState = (): [
  FormContactSnapshot[],
  SetterOrUpdater<FormContactSnapshot[]>
] => useRecoilState(contactListAtomState);

export const useSetContactListAtomState = (): SetterOrUpdater<FormContactSnapshot[]> =>
  useSetRecoilState(contactListAtomState);

export const useContactListAtomValue = (): FormContactSnapshot[] =>
  useRecoilValue(contactListAtomState);

// Use for authorized personas which have access to policy information
export const useInitializeContactListAtomState = () => {
  const setContactListAtomState = useSetContactListAtomState();
  const [userAtomState, _] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const isAgentUser = userPersona === CustomerTypes.SaAgent;
  const isAgentOrAuthInsured = makePersonaCheck(
    userPersona as CustomerTypes,
    [CustomerTypes.SaAgent, CustomerTypes.SaCustomer],
    isAuthInsured,
    true
  );

  const initializeContactList = (
    draftClaimResponse: {result: DraftClaimResponse},
    uuid?: string
  ) => {
    const isCommAuto = draftClaimResponse?.result?.lobs?.hasOwnProperty(ClaimLobs.CommercialAuto);
    const tokenPrefillData = getDefaultValuesFromToken(
      userPersona,
      userAtomState?.gettingStarted?.isAuthInsured
    );
    const userName = getDisplayName(tokenPrefillData?.firstName, tokenPrefillData?.lastName);
    let list: FormContactSnapshot[] = [];
    let noMatchedPolicyContact: boolean = true;

    draftClaimResponse?.result?.contacts?.forEach((contact: Contact) => {
      let prefillOccurrences: ContactSections[] = [];

      // Try to match logged in agent or insured to contact on the policy
      const matchedAuthPolicyContact =
        isAgentOrAuthInsured &&
        noMatchedPolicyContact &&
        authUserMatchesPolicyContact(userPersona as CustomerTypes, userName, contact);

      // Designate contacts to prefill certain sections, if applicable
      if (matchedAuthPolicyContact) {
        prefillOccurrences.push(ContactSections.ReporterAuto, ContactSections.ReporterHome);
        noMatchedPolicyContact = false;
      }

      if (isAgentUser || userPersona === CustomerTypes.Associate) {
        if (hasContactRoles(contact, [ContactRoles.PolicyContactExt]) && isCommAuto) {
          prefillOccurrences.push(ContactSections.InsuredMainContact);
        }

        if (!isCommAuto) {
          if (hasContactRoles(contact, [ContactRoles.Insured])) {
            prefillOccurrences.push(ContactSections.InsuredMainContact);
          }

          if (hasContactRoles(contact, [ContactRoles.Insured, ContactRoles.CoveredParty])) {
            prefillOccurrences.push(ContactSections.InsuredVehicleDriver);
          }
        }

        if (hasContactRoles(contact, [ContactRoles.Insured])) {
          prefillOccurrences.push(ContactSections.InsuredVehicleOwner);
        }
      }

      if (
        isAuthInsured &&
        !isCommAuto &&
        hasContactRoles(contact, [ContactRoles.Insured, ContactRoles.CoveredParty])
      ) {
        prefillOccurrences.push(ContactSections.InsuredVehicleDriver);
      }

      const name =
        contact.displayName ||
        contact.firstName ||
        contact.lastName ||
        contact.suffix ||
        contact.contactName;

      if (contact.publicID && name) {
        list.push({
          value: contact.publicID,
          label: contact.displayName
            ? contact.displayName
            : contact.firstName || contact.lastName || contact.suffix
            ? `${contact.firstName?.trim()} ${contact.lastName?.trim()} ${contact.suffix?.trim()}`
            : contact.contactName || '',
          contactType: (contact.contactType?.toLowerCase() as ContactTypes) || ContactTypes.Person,
          roles: [...((contact.contactRoles as ContactRoles[]) || [])],
          occurrences: [],
          prefillOccurrences,
          policyInfo: contact,
          firstName: contact.firstName,
          suffix: contact.suffix,
          lastName: contact.lastName,
          companyName: contact.contactName,
        });
      }
    });

    // If not matched on the policy, add the logged in agent or insured to the list
    if (isAgentOrAuthInsured && noMatchedPolicyContact && userName) {
      list.push({
        value: uuid || '',
        label: userName,
        contactType: ContactTypes.Person,
        roles: isAgentUser ? [ContactRoles.Agent] : [],
        occurrences: [],
        prefillOccurrences: [ContactSections.ReporterAuto, ContactSections.ReporterHome],
        firstName: tokenPrefillData?.firstName,
        lastName: tokenPrefillData?.lastName,
      });
    }

    setContactListAtomState(list);
  };

  return initializeContactList;
};

export interface ManageContactOccurrences {
  id: string;
  sections: ContactSections[];
}

export const useManageContactOccurrences = () => {
  const setContactListAtomState = useSetContactListAtomState();

  const addContactOccurrences = (occurrences: ManageContactOccurrences[]) => {
    setContactListAtomState((prevState: FormContactSnapshot[]) => {
      let updatedList: FormContactSnapshot[] = [...prevState];

      occurrences.forEach((occ: ManageContactOccurrences) => {
        if (occ.id) {
          const matchingOptionIndex = updatedList.findIndex(
            (co: FormContactSnapshot) => co.value === occ.id
          );

          if (matchingOptionIndex !== -1) {
            const occurrences = [...updatedList[matchingOptionIndex].occurrences, ...occ.sections];

            updatedList.splice(matchingOptionIndex, 1, {
              ...updatedList[matchingOptionIndex],
              occurrences,
            });
          }
        }
      });

      return [...updatedList];
    });
  };

  const removeContactOccurrences = (occurrences: ManageContactOccurrences[]) => {
    setContactListAtomState((prevState: FormContactSnapshot[]) => {
      let updatedList: FormContactSnapshot[] = [...prevState];

      occurrences.forEach((occ: ManageContactOccurrences) => {
        if (occ.id) {
          const matchingOptionIndex = updatedList.findIndex(
            (co: FormContactSnapshot) => co.value === occ.id
          );

          if (matchingOptionIndex !== -1) {
            const occurrences = [...updatedList[matchingOptionIndex].occurrences];

            occ.sections.forEach((section: ContactSections) => {
              const matchingOccurrenceIndex = occurrences.indexOf(section);

              if (matchingOccurrenceIndex !== -1) {
                occurrences.splice(matchingOccurrenceIndex, 1);
              }
            });

            updatedList.splice(matchingOptionIndex, 1, {
              ...updatedList[matchingOptionIndex],
              occurrences,
            });
          }
        }
      });

      return [...updatedList];
    });
  };

  return {addContactOccurrences, removeContactOccurrences};
};

// Use to remove all occurrences of a specified section for all contacts.
export const useRemoveSectionOccurrences = (section: ContactSections) => {
  const setContactListAtomState = useSetContactListAtomState();

  const removeSectionOccurrences = () => {
    setContactListAtomState((prevList: FormContactSnapshot[]) => {
      const updatedList = [...prevList].map((co: FormContactSnapshot) => {
        const occurrences = [...co.occurrences].filter(
          (occurrence: ContactSections) => occurrence !== section
        );

        return {...co, occurrences};
      });

      return updatedList;
    });
  };

  return removeSectionOccurrences;
};

export const useContactListSnapshot = () => {
  const getContactListSnapshot = useRecoilCallback(
    ({snapshot}) =>
      async () => {
        let contactListWithInfo: FormContactSnapshot[] = [];

        try {
          const FormContactSnapshotList = await snapshot.getPromise<FormContactSnapshot[]>(
            contactListAtomState
          );
          const formContactList = await Promise.allSettled(
            FormContactSnapshotList.map((co: FormContactSnapshot) =>
              snapshot.getPromise<FnolFormContact>(formContactAtomState(co.value))
            )
          );

          for (let i: number = 0; i < formContactList.length; i++) {
            const res: PromiseSettledResult<FnolFormContact> = formContactList[i];

            if (res.status === 'fulfilled' && res.value) {
              const {email, phoneNumbers, contactAddress} = res.value;
              contactListWithInfo.push({
                ...FormContactSnapshotList[i],
                email,
                phoneNumbers,
                contactAddress,
              });
            } else if (res.status === 'rejected') {
              contactListWithInfo.push({
                ...FormContactSnapshotList[i],
              });
            }
          }
        } catch (error) {}

        return contactListWithInfo;
      },
    []
  );

  return getContactListSnapshot;
};
/* END CONTACT LIST ATOM STATE */

/* FORM CONTACT ATOM STATE */
// Initializes draft claim contact information by matching the selected ID if authed persona
export const policyContactSelector = selectorFamily({
  key: SelectorKeys.PolicyContactSelector,
  get:
    (id: string) =>
    ({get}) => {
      let formContact: FnolFormContact = {
        fnolId: id,
        firstName: '',
        lastName: '',
        suffix: '',
        companyName: '',
        email: '',
        phoneNumbers: [
          {phoneNumber: '', phoneType: '', phoneExtension: '', verifiedNumber: 'false'},
        ],
        contactAddress: {address: '', city: '', state: '', zipCode: ''},
        contactType: ContactTypes.Person,
      } as FnolFormContact;

      const userAtom = get<UserAtomStateValue>(userAtomState);
      const userPersona = userAtom?.gettingStarted?.customerType;
      const isAuthInsured = userAtom?.gettingStarted?.isAuthInsured;

      if (
        id &&
        makePersonaCheck(
          userPersona as CustomerTypes,
          AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
          isAuthInsured,
          true
        )
      ) {
        const draftClaimResponse = userAtom.draftClaimResponse;
        const uuid = userAtom.uuid;
        const tokenPrefillData = getDefaultValuesFromToken(
          userPersona,
          userAtom?.gettingStarted?.isAuthInsured
        );
        const tokenEmail = tokenPrefillData?.email;
        const userName = getDisplayName(tokenPrefillData?.firstName, tokenPrefillData?.lastName);
        const matchingContact: Contact | undefined = draftClaimResponse?.result?.contacts?.find(
          (contact: Contact) => id === contact.publicID
        );
        const name =
          matchingContact?.firstName ||
          matchingContact?.lastName ||
          matchingContact?.suffix ||
          matchingContact?.displayName ||
          matchingContact?.contactName;

        if (matchingContact && name) {
          let phoneNumbers: FormContactPhone[] = [];
          const formattedCellNumber = formatPhoneToMatchValidation(matchingContact.cellNumber);
          const formattedWorkNumber = formatPhoneToMatchValidation(matchingContact.workNumber);
          const formattedHomeNumber = formatPhoneToMatchValidation(matchingContact.homeNumber);

          if (formattedCellNumber) {
            phoneNumbers.push({
              phoneNumber: formattedCellNumber,
              phoneType: PhoneTypes.Mobile,
              phoneExtension: '',
              verifiedNumber: 'false',
            });
          }

          if (formattedWorkNumber) {
            phoneNumbers.push({
              phoneNumber: formattedWorkNumber,
              phoneType: PhoneTypes.Work,
              phoneExtension: '',
              verifiedNumber: 'false',
            });
          }

          if (formattedHomeNumber) {
            phoneNumbers.push({
              phoneNumber: formattedHomeNumber,
              phoneType: PhoneTypes.Home,
              phoneExtension: '',
              verifiedNumber: 'false',
            });
          }

          if (phoneNumbers.length === 0) {
            phoneNumbers.push({
              phoneNumber: '',
              phoneType: '',
              phoneExtension: '',
              verifiedNumber: 'false',
            });
          }

          const isPerson = matchingContact.contactType?.toLowerCase() === ContactTypes.Person;

          formContact = {
            fnolId: matchingContact.publicID || '',
            firstName: (isPerson && matchingContact.firstName) || '',
            lastName: (isPerson && matchingContact.lastName) || '',
            suffix: (isPerson && matchingContact.suffix) || '',
            companyName:
              (!isPerson && (matchingContact.displayName || matchingContact.contactName)) || '',
            email:
              matchingContact.emailAddress1 ||
              (authUserMatchesPolicyContact(
                userPersona as CustomerTypes,
                userName,
                matchingContact
              ) &&
                tokenEmail?.trim()) ||
              '',
            phoneNumbers,
            contactAddress: {
              address: matchingContact.primaryAddress?.addressLine1 || '',
              city: matchingContact.primaryAddress?.city || '',
              state: matchingContact.primaryAddress?.state || '',
              zipCode: matchingContact.primaryAddress?.postalCode || '',
            },
            contactType:
              (matchingContact.contactType?.toLowerCase() as ContactTypes) || ContactTypes.Person,
            policyInfo: matchingContact,
          };
        } else if (id === uuid) {
          formContact = {
            fnolId: id,
            firstName: tokenPrefillData?.firstName?.trim(),
            lastName: tokenPrefillData?.lastName?.trim(),
            companyName: '',
            email: tokenEmail?.trim() || '',
            phoneNumbers: [
              {phoneNumber: '', phoneType: '', phoneExtension: '', verifiedNumber: 'false'},
            ],
            contactAddress: {address: '', city: '', state: '', zipCode: ''},
            contactType: ContactTypes.Person,
          };
        }
      }

      return formContact;
    },
});

export const formContactAtomState = AtomFactory.createAtomFamily({
  uniqueId: AtomKeys.FormContactAtomState,
  defaultValue: policyContactSelector,
});

export const useFormContactAtomState = (
  id: string
): [FnolFormContact, SetterOrUpdater<FnolFormContact>] => useRecoilState(formContactAtomState(id));

export const useSetFormContactAtomState = (id: string): SetterOrUpdater<FnolFormContact> =>
  useSetRecoilState(formContactAtomState(id));
/* END FORM CONTACT ATOM STATE */

/* CATASTROPHE ATOM STATE */
export interface CatastropheAtomState {
  catastrophe_number: string;
  description: string;
  state: string;
  begin_date: string;
  end_date: string;
}
export const catastropheAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.CatastropheAtomState,
  defaultValue: [],
});

export const useCatastropheAtomState = (): [
  CatastropheAtomState[],
  SetterOrUpdater<CatastropheAtomState[]>
] => useRecoilState(catastropheAtomState);

export const useSetCatastropheAtomState = (): SetterOrUpdater<CatastropheAtomState[]> =>
  useSetRecoilState(catastropheAtomState);

/* END CATASTROPHE ATOM STATE */

/* RESET ATOM STATE */
export const useResetAtomState = () => {
  const setReporterAtomState = useSetReporterAtomState();
  const setAdditionalNotesAtomState = useSetAdditionalNotesAtomState();
  const setContactListAtomState = useSetContactListAtomState();
  const setNoticeOnly = useSetNoticeOnlyAtomState();
  const setVehicleOptionsAtomState = useSetVehicleContactAtomState();
  const resetAtomState = () => {
    setReporterAtomState({...DEFAULT_REPORTER_ATOM_VALUE});
    setContactListAtomState([]);
    setAdditionalNotesAtomState({additionalNotes: ''});
    setNoticeOnly(false);
    setVehicleOptionsAtomState([]);
  };

  return resetAtomState;
};
/* END RESET ATOM STATE */

/* ADDITIONAL NOTES ATOM STATE */
export interface AdditionalNotesAtomStateValue {
  additionalNotes?: string;
}

export const additionalNotesAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.AdditionalNotesAtomState,
  defaultValue: {} as AdditionalNotesAtomStateValue,
});

export const useSetAdditionalNotesAtomState = (): SetterOrUpdater<AdditionalNotesAtomStateValue> =>
  useSetRecoilState(additionalNotesAtomState);

export const useGetAdditionalNotesAtomState = (): AdditionalNotesAtomStateValue =>
  useRecoilValue(additionalNotesAtomState);
/*END ADDITIONAL NOTES ATOM STATE */

/* ADD NOTES MODAL ATOM STATE */
export interface AddNotesModalAtomStateValue {
  open: boolean;
}

export const addNotesModalAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.AddNotesModalAtomState,
  defaultValue: {open: false} as AddNotesModalAtomStateValue,
});

export const useAddNotesModalAtomState = (): [
  AddNotesModalAtomStateValue,
  SetterOrUpdater<AddNotesModalAtomStateValue>
] => useRecoilState(addNotesModalAtomState);

export const useSetAddNotesModalAtomState = (): SetterOrUpdater<AddNotesModalAtomStateValue> =>
  useSetRecoilState(addNotesModalAtomState);

export const useGetAddNotesModalAtomState = (): AddNotesModalAtomStateValue =>
  useRecoilValue(addNotesModalAtomState);
/*END ADD NOTES MODAL ATOM STATE */

/* NOTICE ONLY ATOM STATE */
export const noticeOnlyAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.NoticeOnlyAtomState,
  defaultValue: false as boolean,
});

export const useSetNoticeOnlyAtomState = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(noticeOnlyAtomState);

export const useGetNoticeOnlyAtomState = (): boolean => useRecoilValue(noticeOnlyAtomState);
/*END NOTICE ONLY ATOM STATE */

/*VEHICLE OPTIONS ATOM STATE*/
export const vehicleOptionsAtomState = AtomFactory.createAtom({
  uniqueId: AtomKeys.VehicleOptionsAtomState,
  defaultValue: [] as Vehicle[],
});

export const useVehicleOptionsAtomState = (): [Vehicle[], SetterOrUpdater<Vehicle[]>] =>
  useRecoilState(vehicleOptionsAtomState);

export const useSetVehicleOptionsAtomState = (): SetterOrUpdater<Vehicle[]> =>
  useSetRecoilState(vehicleOptionsAtomState);

/*END VEHICLE OPTIONS ATOM STATE*/
