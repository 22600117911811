import React, {createRef, useEffect, useState, useRef} from 'react';
import * as yup from 'yup';
import {formatISO, format} from 'date-fns';
import {
  Box,
  Grid,
  Paper,
  Container,
  Divider,
  useMediaQuery,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {FormProvider, useForm, Controller, useWatch} from 'react-hook-form';
import {AlertPanel} from '../common/AlertPanel/AlertPanel';
import {Icon} from '../common/Icon/Icon';
import {draftClaim, duplicateCheck, verifyPolicy, VerifyPolicyPayload} from '../../services';
import {SADatePicker} from '../common/DatePicker/DatePicker';
import {SATimePicker} from '../common/TimePicker/TimePicker';
import {SATextField} from '../common/TextField/TextField';
import {yupResolver} from '@hookform/resolvers';
import {getUserInfoFromToken, returnRecentDuplicateClaims, scrollToRef} from '../../utils/utils';
import {PolicyNumberSchema, LOB_PROPS} from '../auto/GettingStarted/GettingStartedEntry';
import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie';
import {
  GlassRoadside,
  glassOnlyMessage,
  roadsideAssistanceMessage,
  GlassRoadsideTypes,
} from '../auto/GlassRoadside/GlassRoadside';
import {PhoneHyperlink} from '../common/PhoneHyperlink/PhoneHyperlink';
import {COMMERCIAL_AUTO_TYPES, CustomerTypes, Lobs, UserData} from '../../commonTypes';
import {Snowplow} from '../../pages/utils/snowplow';
import {SAButton} from '../common/Button/Button';
import {DuplicateClaimDialog} from '../common/DuplicateClaimDialog/DuplicateClaimDialog';
import {
  useInitializeContactListAtomState,
  useSetVehicleOptionsAtomState,
  useUserAtomState,
} from '../../atoms';
import {PolicySearchForm} from './PolicySearch';
import {navigateDefaultReplace} from '../../pages/utils';
import {LossDateAndTimeSchema} from '../../validations';
import {useFeatureFlags} from '../common/Providers/Providers';
import {SABox, SAIcon, SAIcons, SAIconSize, SATooltip} from '@saux/design-system-react';
import {COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE, MAINTENANCE_ROUTE} from '../../routes/paths';
import {navigate} from '@reach/router';

const TimePickerHint = withStyles({
  root: {
    marginLeft: 10,
  },
})(FormHelperText);

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  policySearchContainer: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  policySearchBox: {
    width: '100%',
    margin: '0 auto',
  },
  errorMessage: {
    color: '#C74F32',
    fontWeight: 'bold',
  },
}));

export const PolicySearchSchema = yup.object().shape({
  policyNumber: PolicyNumberSchema,
  ...LossDateAndTimeSchema,
  firstName: yup.string().when('policyType', {
    is: (value: string) => value === 'person',
    then: yup.string().required('Enter a valid first name'),
  }),
  lastName: yup.string().when('policyType', {
    is: (value: string) => value === 'person',
    then: yup.string().required('Enter a valid last name'),
  }),
  companyName: yup.string().when('policyType', {
    is: (value: string) => value === 'company',
    then: yup.string().required('Enter a valid company name'),
  }),
});

const uuid = uuidv4();

export const AgentPolicySearch = () => {
  const {featureFlags} = useFeatureFlags();
  const [userAtomState, setUserAtomState] = useUserAtomState();
  const {gettingStarted, policyNumber} = userAtomState;
  const lob = gettingStarted?.lob || '';
  const customerType = gettingStarted?.customerType;
  const policyNumberPrefill = policyNumber;
  const claimsOutage = featureFlags?.FF_DCX_2688;
  const policyCenterDown = featureFlags?.FF_DCARE_6280;
  const [hasDuplicateClaims, setHasDuplicateClaims] = useState(false);
  const [duplicateClaimData, setDuplicateClaimData] = useState<any>([{}]);
  const [policyType, setPolicyType] = useState('');
  const [insuredName, setInsuredName] = useState('');
  const initializeContactList = useInitializeContactListAtomState();
  const setVehicleOptionsAtomState = useSetVehicleOptionsAtomState();

  const formMethods = useForm<PolicySearchForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    resolver: yupResolver(PolicySearchSchema),
    defaultValues: {
      lossDate: 0,
      policyType: 'person',
    },
    context: {
      lob: lob,
    },
  });

  const {register, errors, handleSubmit, formState, setValue, trigger, getValues, control} =
    formMethods;

  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const [dataForCA, setDataForCA] = useState<UserData>();
  const [isHidden, setIsHidden] = useState(true);
  const [policySearchError, setPolicySearchError] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [policySearchMatch, setPolicyMatchSuccess] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [glassRoadsideError, setGlassRoadsideError] = useState<
    string | React.ReactElement | undefined | null
  >();

  const [glassRoadsideState, setGlassRoadsideState] = useState<string>(GlassRoadsideTypes.Neither);
  const handleGlassRoadsideChange = (glassRoadsideValue: string) => {
    setGlassRoadsideState(glassRoadsideValue);
    if (
      glassRoadsideValue === GlassRoadsideTypes.GlassOnly ||
      glassRoadsideValue === GlassRoadsideTypes.RoadsideAssistance
    ) {
      setGlassRoadsideError(
        glassRoadsideValue === GlassRoadsideTypes.GlassOnly
          ? glassOnlyMessage
          : roadsideAssistanceMessage
      );
      setPolicyMatchSuccess(null);
      setPolicySearchError(null);
      setIsHidden(true);
      setIsSubmitting(false);
    } else if (glassRoadsideValue === GlassRoadsideTypes.Neither) {
      setGlassRoadsideError(null);
    }
  };

  const isCompanyPolicy = useWatch({control, name: 'policyType'}) === 'company';

  const getPolicyTypeErrorMessage = () => {
    return (
      <span id="policyTypeError" data-testid="policyTypeError">
        {LOB_PROPS[lob].policyTypeErrorMessage}
      </span>
    );
  };

  const getAgentProducerCodeErrorMessage = () => {
    return (
      <span id="agentProducerCodeError" data-testid="agentProducerCodeError">
        Sorry, we’re unable to validate you are the Agent of Record for this Insured. Please call us
        at <PhoneHyperlink /> to report this claim.
      </span>
    );
  };

  const reset = () => {
    setIsSubmitting(false);
    setIsContinuing(true);
    setIsHidden(true);
  };

  const preserve = () => {
    setIsSubmitting(false);
    setIsHidden(false);
    setIsContinuing(false);
  };

  const onSubmit = (data: PolicySearchForm) => {
    setIsSubmitting(true);
    setIsContinuing(true);
    setIsHidden(true);
    setPolicySearchError(null);
    setGlassRoadsideError(null);
    setPolicyMatchSuccess(null);

    const payload: VerifyPolicyPayload = {};
    const lossDateFormatted = formatISO(new Date(data.lossDate));
    const lossTimeFormatted = format(data.lossTime, 'hh:mm a');

    payload.policyNumber = data.policyNumber.replace(/\s+/g, '');
    payload.lossDate = lossDateFormatted;
    payload.lossTime = lossTimeFormatted;

    if (data.firstName !== '') {
      payload.firstName = data.firstName;
    }

    if (data.lastName !== '') {
      payload.lastName = data.lastName;
    }

    if (data.companyName !== '') {
      payload.companyName = data.companyName;
    }

    payload.policyholderType = data.policyType;

    payload.lineOfBusiness = lob;
    payload.agentSearch = customerType === CustomerTypes.SaAgent;

    const {policyNumber} = data;
    const userToken = Cookies.get('userToken');

    verifyPolicy(payload)
      .then(response => {
        const {data, headers} = response;

        const {firstName, lastName} = getUserInfoFromToken();
        const {insured, address, city, state, zip, policyType} = data.data as unknown as UserData;
        setDataForCA(data.data as UserData);
        Snowplow.track.login({
          isCompanyPolicy: (policyType && COMMERCIAL_AUTO_TYPES.includes(policyType)) || false,
          payload: {
            firstName: firstName,
            lastName: lastName,
            lossDate: payload.lossDate as string,
            lossTime: payload.lossTime as string,
            policyNumber: payload.policyNumber as string,
            companyName: payload.companyName as string,
          },
          data,
          isAuthedUser: true,
          logrocketurl: 'N/A',
          persona: CustomerTypes.SaAgent,
        });

        if (data.data) {
          if (!userToken) {
            Cookies.set('userToken', headers?.['x-amzn-remapped-authorization'] || data?.jwt);
          }

          Cookies.set('policyNumber', policyNumber);
          setPolicyType(policyType);
          setPolicyMatchSuccess(
            <Box style={{backgroundColor: 'rgba(24, 158, 204, .08)'}}>
              <Box p={2}>
                <Box pb={3} fontWeight="bold">
                  We've found a match:
                </Box>
                <Box fontWeight="bold">{insured}</Box>
                <Box>{address}</Box>
                <Box>{city + ', ' + state + ' ' + zip}</Box>
              </Box>
            </Box>
          );

          if (typeof data.data !== 'boolean') {
            setInsuredName(insured);
          }
          duplicateCheck({policyNumber, lossDate: lossDateFormatted})
            .then(({data}: any) => {
              if (data.data instanceof Array && data.data.length > 0) {
                setHasDuplicateClaims(true);
                setDuplicateClaimData(returnRecentDuplicateClaims(data.data));
              } else {
                preserve();
              }
            })
            .catch(error => {
              preserve();
            });
        } else {
          if (policyCenterDown) {
            navigate(MAINTENANCE_ROUTE);
          } else {
            setPolicySearchError(
              <span data-testid="policyMatchError" id="policyMatchError">
                We couldn't match the policy information you entered. Please double-check your
                search criteria and try again, or contact our claims team at <PhoneHyperlink />.
              </span>
            );
            reset();
          }
        }
      })
      .catch(error => {
        if (error?.response?.status === 406) {
          setPolicySearchError(getPolicyTypeErrorMessage());
        } else if (error?.response?.status === 451) {
          setPolicySearchError(getAgentProducerCodeErrorMessage());
        } else {
          setPolicySearchError(
            <span data-testid="errorMessage" id="genericPolicyError">
              Something went wrong. Please contact our claims team at <PhoneHyperlink />.
            </span>
          );
        }
        reset();
      });
  };

  const onContinue = () => {
    setIsContinuing(true);
    const data = getValues();
    const {lossDate, lossTime, policyNumber} = data;
    const lossDateFormatted = formatISO(new Date(data.lossDate));
    const lossTimeFormatted = format(lossTime, 'hh:mm a');

    setUserAtomState(state => {
      return {
        ...state,
        lossDate: lossDate,
        lossTime: lossTime,
        policyNumber: policyNumber.replace(/\s+/g, ''),
        uuid: uuid,
      };
    });

    if (
      featureFlags.FF_DCX_2945 &&
      dataForCA &&
      COMMERCIAL_AUTO_TYPES.includes(dataForCA['policyType']) &&
      !dataForCA.policyNumber.toLowerCase().startsWith('bap') &&
      dataForCA.vehicles?.length
    ) {
      navigate(COMMERCIAL_AUTO_VEHICLE_SELECTION_ROUTE, {
        state: {
          userData: {...dataForCA},
          dataForDraftClaim: {
            lossDate,
            lossTime,
            lossDateFormatted,
            lossTimeFormatted,
            policyNumber,
            uuid,
          },
          isAssociate: false,
        },
      });
    } else {
      draftClaim({
        lossDate: lossDateFormatted,
        lossTime: lossTimeFormatted,
        policyNumber,
        uuid,
      })
        .then(response => {
          const {data} = response;

          setUserAtomState(state => {
            return {
              ...state,
              lossDate: lossDate,
              lossTime: lossTime,
              policyNumber: policyNumber.replace(/\s+/g, ''),
              uuid: uuid,
              draftClaimResponse: data.data.draftClaimResponse,
            };
          });
          setVehicleOptionsAtomState(
            data.data?.draftClaimResponse?.result?.lobs?.personalAuto?.vehicles ||
              data.data?.draftClaimResponse?.result?.lobs?.commercialAuto?.vehicles
          );
          initializeContactList(data.data.draftClaimResponse, data.data.uuid);
          navigateDefaultReplace(LOB_PROPS[lob].formPath);
        })
        .catch(_ => {
          setIsSubmitting(false);
          setPolicyMatchSuccess(null);
          setHasDuplicateClaims(false);
          setPolicySearchError(
            <span>
              An issue occurred with the claim setup process. Please contact our CARE team at{' '}
              <PhoneHyperlink />.
            </span>
          );
        });
    }
  };

  const dateTimeChangeHandler = (date: Date | null, value?: string | null | undefined) => {
    if (date instanceof Date && date.toString() !== 'Invalid Date') {
      setValue('lossDate', date);
      setDateSelected(date);
      trigger('lossDate');
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const errorPanelRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (errorPanelRef.current) {
      scrollToRef(errorPanelRef);
    }
  }, [policySearchError]);

  const onEscapeKeyDown = () => {
    setPolicyMatchSuccess(null);
    setIsSubmitting(false);
    setHasDuplicateClaims(false);
  };

  const incidentTimeRef = useRef(null);
  const onError = () => {
    scrollToRef(incidentTimeRef);
  };

  return (
    <Container>
      <Box className={classes.policySearchBox} mb={3}>
        <Paper elevation={3}>
          <Box
            bgcolor="primary.main"
            color="white"
            fontWeight="fontWeightMedium"
            fontSize={16}
            px={3}
            height="50px"
            display="flex"
            alignItems="center"
          >
            Policy Information
          </Box>
          <Box p={3}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
              <Grid container xs={12} spacing={3}>
                <Grid container item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Grid container item xs={12}>
                      <Grid item xs={12} md={1} className={classes.tooltipContainer}>
                        <SATooltip
                          wrapperTestId="title-test-wrapper"
                          testId="title-test"
                          content={
                            <>
                              If you are missing information contact CARE support at
                              <PhoneHyperlink /> for further assistance in setting up your claim.
                            </>
                          }
                          variant="light"
                          placement="top"
                        >
                          <SABox marginTop="medium" marginLeft="small">
                            <SAIcon
                              icon={SAIcons.help}
                              size={SAIconSize.medium}
                              colorVariant="dark"
                            />
                          </SABox>
                        </SATooltip>
                      </Grid>
                      <Grid item xs={12} md={11} className={classes.policySearchContainer}>
                        <Box pb={3}>
                          <SATextField
                            autoComplete="on"
                            name="policyNumber"
                            label="State Auto Policy Number"
                            id="policyNumber"
                            InputLabelProps={{'aria-labelledby': 'policyNumber'}}
                            error={errors.hasOwnProperty('policyNumber')}
                            helperText={
                              errors?.policyNumber?.message || LOB_PROPS[lob].policyNumberHelperText
                            }
                            inputRef={register()}
                            autoFocus
                            defaultValue={policyNumberPrefill || ''}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Box pb={3}>
                      <Box>
                        <SADatePicker
                          id="lossDate"
                          label="Incident Date"
                          name="lossDate"
                          value={dateSelected}
                          InputLabelProps={{'aria-labelledby': 'lossDate'}}
                          error={errors.hasOwnProperty('lossDate')}
                          helperText={errors.lossDate?.message}
                          keyboardIcon={<Icon name="calendar" />}
                          onChange={dateTimeChangeHandler}
                          inputRef={register()}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={11}>
                    <Box pb={3}>
                      <div ref={incidentTimeRef} />
                      <Controller
                        name="lossTime"
                        defaultValue={null}
                        control={control}
                        onFocus={onError}
                        render={({value, onChange, onBlur}) => (
                          <SATimePicker
                            label="Incident Time"
                            disabled={false}
                            onChange={(date: Date) => {
                              onChange(date);
                              trigger('lossTime');
                            }}
                            onBlur={() => {
                              trigger('lossTime');
                            }}
                            value={value}
                            keyboardIcon={<Icon name="clock" />}
                            error={errors.hasOwnProperty('lossTime')}
                            InputLabelProps={{
                              'aria-labelledby': 'lossTime',
                              for: 'lossTime',
                            }}
                            id="lossTime"
                          />
                        )}
                      />

                      <TimePickerHint error={errors.hasOwnProperty('lossTime')}>
                        {errors?.lossTime?.message || 'General Estimate'}
                      </TimePickerHint>
                    </Box>
                  </Grid>
                  {lob === Lobs.Auto && (
                    <FormProvider {...formMethods}>
                      <GlassRoadside
                        glassRoadsideState={handleGlassRoadsideChange}
                        defaultValue="neither"
                      />
                    </FormProvider>
                  )}
                  <Grid item xs={12} md={11}>
                    <Box display="flex" justifyContent="flex-end">
                      <SAButton
                        disabled={
                          !formState.isValid ||
                          isSubmitting ||
                          glassRoadsideState !== GlassRoadsideTypes.Neither ||
                          claimsOutage
                        }
                        data-testid="search-button"
                        variant="contained"
                        color="secondary"
                        type="submit"
                        loading={isSubmitting}
                      >
                        Search
                      </SAButton>
                    </Box>
                  </Grid>
                </Grid>
                {policySearchError && (
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      height="100%"
                      width="100%"
                    >
                      <Divider
                        style={isMobile ? {marginBottom: '30px'} : {}}
                        variant={isMobile ? 'fullWidth' : 'middle'}
                        orientation={isMobile ? 'horizontal' : 'vertical'}
                        flexItem={!isMobile}
                      />
                      <Box>
                        <AlertPanel
                          alert={{
                            message: policySearchError,
                            showWarning: false,
                          }}
                          data-testid="error-panel"
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
                {glassRoadsideError && (
                  <Grid item xs={12} md={6} className={classes.errorMessage}>
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      height="100%"
                      width="100%"
                    >
                      <Divider
                        style={isMobile ? {marginBottom: '30px'} : {}}
                        variant={isMobile ? 'fullWidth' : 'middle'}
                        orientation={isMobile ? 'horizontal' : 'vertical'}
                        flexItem={!isMobile}
                      />
                      <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        height="100%"
                        width="100%"
                      >
                        <Box>{glassRoadsideError}</Box>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {policySearchMatch && (
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      height="100%"
                      width="100%"
                    >
                      <Divider
                        style={isMobile ? {marginBottom: '30px'} : {}}
                        variant={isMobile ? 'fullWidth' : 'middle'}
                        orientation={isMobile ? 'horizontal' : 'vertical'}
                        flexItem={!isMobile}
                      />
                      <Box width="100%">{policySearchMatch}</Box>
                    </Box>
                  </Grid>
                )}
                {!isHidden && (
                  <Box textAlign="right" width="100%">
                    <SAButton
                      onClick={onContinue}
                      disabled={isContinuing}
                      data-testid="continue-button"
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={isContinuing}
                    >
                      Continue
                    </SAButton>
                  </Box>
                )}
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
      <DuplicateClaimDialog
        open={hasDuplicateClaims}
        continueToForm={onContinue}
        onEscapeKeyDown={onEscapeKeyDown}
        authed
        data={{
          insuredName,
          incidentDate: getValues()?.lossDate,
          policyNumber: getValues()?.policyNumber,
          policyType: policyType,
          claims: [...duplicateClaimData],
        }}
      />
    </Container>
  );
};
