import React, {useEffect, useState} from 'react';
import {
  SAAlert,
  SABox,
  SAButton,
  SACard,
  SACheckbox,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SARadio,
  SARadioGroup,
  SASearch,
  SASelect,
  SASelectOption,
  SATable,
  SATableColumn,
  SATag,
  SAText,
} from '@saux/design-system-react';
import styled from 'styled-components';
import {CustomerTypes, UserData} from '../../../commonTypes';
import {register} from '../../../serviceWorker';
import {draftClaim} from '../../../services';
import {
  useInitializeContactListAtomState,
  useSetVehicleOptionsAtomState,
  useUserAtomState,
} from '../../../atoms';
import {LOB_PROPS} from '../../auto/GettingStarted/GettingStartedEntry';
import {navigateDefaultReplace} from '../../../pages/utils';
import {PhoneHyperlink} from '../PhoneHyperlink/PhoneHyperlink';
import {Snowplow} from '../../../pages/utils/snowplow';
import {getUserInfoFromToken} from '../../../utils/utils';
import {navigate} from '@reach/router';
import Cookies from 'js-cookie';

const SAColumnCenter = styled(SAColumns)`
  justify-content: center;
`;

const AlignCenterBox = styled(SABox)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  font-weight: bold;
  list-style: none;
  font-size: small;
  padding: 8;
`;

const RighJustified = styled(SABox)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 5%;
`;

export interface VehicleState {
  make: string;
  model: string;
  year: string;
  vIN: string;
}

export interface CommercialAutoNavState {
  userData: UserData;
  dataForDraftClaim: {
    lossDate: Date | number;
    lossTime: Date | number;
    lossDateFormatted: string;
    lossTimeFormatted: string;
    policyNumber: string;
    uuid: string;
  };
  dataForDraftClaimAssociate?: {
    userToken?: string;
    companyName?: string;
    verifyPolicyHeaders?: any;
  };
}
interface CommercialAutoProps {
  userData: CommercialAutoNavState;
}

type TableData = [
  {
    label: string;
    id: string;
  },
  string,
  string
];

interface VehicleOptions {
  label: string;
  value: string;
}
//All the commented out code is used for year filtering, adding to the table and sorting! We can uncomment it one PC brings in the year data: https://stateautoinsurance.atlassian.net/browse/BAPGW-678
export const CommercialAutoSelection = ({userData}: CommercialAutoProps) => {
  const {insured, vehicles} = userData.userData;
  const dataForDraftClaim = userData?.dataForDraftClaim;
  const dataForDraftClaimAssociate = userData?.dataForDraftClaimAssociate;
  const [saSelectOptionsMake, setSaSelectOptionsMake] = useState<VehicleOptions[]>([
    {label: 'Make', value: 'Make'},
  ]);
  const [saSelectOptionsModel, setSaSelectOptionsModel] = useState<VehicleOptions[]>([
    {label: 'Model', value: 'Model'},
  ]);
  // const [saSelectOptionsYear, setSaSelectOptionsYear] = useState<VehicleOptions[]>([{label: 'Year', value: 'Year'}]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [originaltableData, setOriginaltableData] = useState<TableData[]>([]);
  // const [filters, setFilters] = useState<{
  //   make: string[];
  //   model: string[];
  //   year: string[];
  // }>({make: [], model: [], year: []});
  const [filters, setFilters] = useState<{
    make: string[];
    model: string[];
  }>({make: [], model: []});
  const [checked, setChecked] = useState<{[key: string]: boolean}>({});
  const [vinSearch, setVinSearch] = useState<boolean>(false);
  const [arrowUp, setArrowUp] = useState<boolean[]>([false, false, false]);
  const [click, setClick] = useState<boolean[]>([true, false, false]);
  const [checkedVehicleState, setCheckedVehicleState] = useState<VehicleState[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [checkedVehicleVinState, setCheckedVehicleVinState] = useState<string[]>([]);

  const [userAtomState, setUserAtomState] = useUserAtomState();
  const {gettingStarted} = userAtomState;
  const lob = gettingStarted?.lob || '';
  const [policySearchError, setPolicySearchError] = useState<
    string | React.ReactElement | undefined | null
  >();
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [yesSelected, setYesSelected] = useState<boolean | undefined>(undefined);
  const [fourVehicles, setFourVehicles] = useState<boolean>(false);
  const [showSelectedError, setShowSelectedError] = useState<boolean>(false);
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const setVehicleOptionsAtomState = useSetVehicleOptionsAtomState();
  const initializeContactList = useInitializeContactListAtomState();
  const {firstName, lastName} = getUserInfoFromToken();

  const tableDataSort = (index: number, arrowState: boolean[]) => (a: any, b: any) => {
    let greaterThan = 1;
    let lessThan = -1;

    if (arrowState[index]) {
      greaterThan = -1;
      lessThan = 1;
    }

    if (index === 0) {
      return a[index]['label'] > b[index]['label'] ? greaterThan : lessThan;
    } else {
      return a[index] > b[index] ? greaterThan : lessThan;
    }
  };

  const onClick = (indexClicked: number) => {
    const header = arrowUp[indexClicked];
    let clickTemp = [...click];
    let arrowTemp = [...arrowUp];

    click.forEach((_: boolean, index: number) => {
      clickTemp[index] = indexClicked === index;
    });

    arrowUp.forEach((_: boolean, index: number) => {
      arrowTemp[index] = indexClicked === index ? !header : false;
    });

    setClick(clickTemp);

    setArrowUp(arrowTemp);

    setTableData([...tableData].sort(tableDataSort(indexClicked, arrowTemp)));
  };

  const CheckBoxHolder = ({
    id,
    label,
    index,
  }: {
    id: keyof typeof checked;
    label: 'string';
    index: number;
  }) => {
    return (
      <SACheckbox
        id={`${id}`}
        label={label}
        onChange={e => setChecked({...checked, [id]: e.currentTarget.checked})}
        checked={checked[id]}
        disabled={checked[id] ? false : disabled}
        data-testid={index + `${id}`}
      />
    );
  };

  const processColumn1 = ({
    label,
    id,
    index,
  }: {
    id: keyof typeof checked;
    label: 'string';
    index: number;
  }) => {
    return <CheckBoxHolder id={id} label={label} index={index} />;
  };

  const upDownArrowHeader = (headerName: string, index: number) => {
    return (
      <Button
        onClick={() => {
          onClick(index);
        }}
      >
        <span data-testid={headerName + ' header'}> {headerName} </span>
        {click[index] ? (
          arrowUp[index] ? (
            <SAIcon
              icon={SAIcons.chevronUp}
              size={SAIconSize.small}
              data-testid={'upArrow'}
              colorVariant="dark"
            />
          ) : (
            <SAIcon
              icon={SAIcons.chevronDown}
              size={SAIconSize.small}
              data-testid={'downArrow'}
              colorVariant="dark"
            />
          )
        ) : (
          ' '
        )}
      </Button>
    );
  };

  const processVin = (value: string) => {
    return value.slice(-6);
  };

  const columns: SATableColumn[] = [
    // {
    //   name: upDownArrowHeader('Year', 0),
    //   process: processColumn1,
    // },
    {
      name: upDownArrowHeader('Make', 0),
      process: processColumn1,
    },
    {
      name: upDownArrowHeader('Model', 1),
    },
    {
      name: upDownArrowHeader('VIN (LAST 6 DIGITS)', 2),
      process: processVin,
    },
  ];

  const sortFunctionSelection = (value: string) => (a: any, b: any) => {
    if (a.label === value) {
      return -1;
    } else if (b.label === value) {
      return 1;
    } else {
      return a.label > b.label ? 1 : -1;
    }
  };
  useEffect(() => {
    let table: any = [];
    let selectOptionsMake: VehicleOptions[] = [];
    let selectOptionsModel: VehicleOptions[] = [];
    // let selectOptionsYear: any = [];
    let checkTemp: {[key: string]: boolean} = {...checked};

    vehicles?.forEach((vehicle: any) => {
      const make = vehicle.make || '-';
      const model = vehicle.model || '-';
      // const year = vehicle.year || '-';
      const vin = vehicle.vIN;
      checkTemp[vin] = false;
      // table.push([{label: year, id: vin},make, model, vin]);
      table.push([{label: make, id: vin}, model, vin]);
      if (make) {
        if (!selectOptionsMake?.find((currentMake: any) => currentMake.label === make)) {
          selectOptionsMake?.push({label: make, value: make});
        }
      }
      if (model) {
        if (!selectOptionsModel?.find((currentModel: any) => currentModel.label === model)) {
          selectOptionsModel?.push({label: model, value: model});
        }
      }
      // if (year) {
      //   if (!selectOptionsYear?.find((currentYear: any) => currentYear.label === year)) {
      //     selectOptionsYear?.push({label: year, value: year});
      //   }
      // }
    });

    //sorting defualt based off of make
    table.sort((a: any, b: any) => {
      return a[0]['label'] > b[0]['label'] ? 1 : -1;
    });

    setOriginaltableData(table);
    setTableData(table);

    selectOptionsMake?.sort(sortFunctionSelection('Make'));
    selectOptionsModel.sort(sortFunctionSelection('Model'));
    // selectOptionsYear.sort(sortFunctionSelection('Year'));

    setSaSelectOptionsMake(selectOptionsMake);
    setSaSelectOptionsModel(selectOptionsModel);
    // setSaSelectOptionsYear(selectOptionsYear);

    setChecked(checkTemp);
  }, [userData]);

  const onChangeFilters = (event: any, change: string) => {
    const value = event.target.value;
    if (value != '') {
      setFilters({...filters, [change]: [...value]});
    } else {
      setFilters({...filters, [change]: []});
    }
  };

  const handleSearch = (search: Array<string> | string) => {
    if (search.length === 0) {
      setTableData(originaltableData);
    } else {
      let searchedData = originaltableData;
      searchedData = searchedData.filter(vehicle => {
        // const vin = vehicle[3] as String;
        const vin = vehicle[2] as string;
        return search.includes(vin.slice(-6));
      });
      const index = click.indexOf(true);
      searchedData.sort(tableDataSort(index, arrowUp));
      setTableData(searchedData);
    }
  };

  useEffect(() => {
    // if (filters.make.length || filters.model.length || filters.year.length) {
    if (filters.make.length || filters.model.length) {
      let filteredData = [...originaltableData];
      const make = filters.make;
      const model = filters.model;
      // const year = filters.year;

      if (make.length) {
        filteredData = filteredData?.filter(vehicle => {
          return make.includes(vehicle[0]['label']);
        });
      }

      if (model.length) {
        filteredData = filteredData?.filter(vehicle => {
          return model.includes(vehicle[1]);
        });
      }
      // if (year.length) {
      //   filteredData = filteredData?.filter(vehicle => {
      //     return year.includes(vehicle[0]['label']);
      //   });
      // }
      const index = click.indexOf(true);
      filteredData.sort(tableDataSort(index, arrowUp));
      setTableData(filteredData);
    } else {
      setTableData([...originaltableData]);
    }
  }, [filters, originaltableData]);

  const getObjKeys = (obj: any, value: boolean) => {
    return Object.keys(obj).filter(key => obj[key] === value);
  };

  useEffect(() => {
    const checkedVehicleVin = getObjKeys(checked, true);
    let checkedVehicle: VehicleState[] | undefined = [];
    checkedVehicle = vehicles?.filter((vehicle: VehicleState) => {
      return checkedVehicleVin.includes(vehicle['vIN']);
    });
    const fourVehicles = checkedVehicleVin.length === 4;
    setDisabled(fourVehicles);
    setFourVehicles(fourVehicles);
    checkedVehicle && setCheckedVehicleState(checkedVehicle);
    setCheckedVehicleVinState(checkedVehicleVin);
  }, [checked]);

  useEffect(() => {
    setShowAlert(yesSelected || fourVehicles);
  }, [fourVehicles, yesSelected]);

  const handleClose = (_: React.MouseEvent<HTMLElement, MouseEvent>, vin: string) => {
    let checkedVechicle = [...checkedVehicleState];
    const filteredArray = checkedVechicle.filter(vehicle => !(vehicle['vIN'] === vin));
    setChecked(checked => {
      return {...checked, [vin]: false};
    });
    setCheckedVehicleState(filteredArray);
  };

  const responseForDraftClaim = (
    response: any,
    policyNumber: string,
    lossDateFormatted: string,
    lossTimeFormatted: string,
    lossDate: Date | number,
    lossTime: Date | number,
    uuid?: string
  ) => {
    const {data} = response;
    const userPersona = userAtomState.gettingStarted?.customerType;

    setVehicleOptionsAtomState(data.data.draftClaimResponse.result.lobs.commercialAuto?.vehicles);

    if (userPersona === CustomerTypes.Associate) {
      if (dataForDraftClaimAssociate) {
        const {userToken, companyName, verifyPolicyHeaders} = dataForDraftClaimAssociate;
        Snowplow.track.login({
          isCompanyPolicy: true,
          payload: {
            firstName,
            lastName,
            lossDate: lossDateFormatted as string,
            lossTime: lossDateFormatted as string,
            policyNumber,
            companyName: companyName as string,
          },
          data,
          isAuthedUser: true,
          logrocketurl: 'N/A',
          persona: CustomerTypes.Associate,
        });

        if (!userToken) {
          Cookies.set(
            'userToken',
            verifyPolicyHeaders?.['x-amzn-remapped-authorization'] || data?.jwt
          );
        }
      }
    } else if (userAtomState.gettingStarted?.isAuthInsured) {
      Snowplow.track.login({
        isCompanyPolicy: true,
        payload: {
          firstName: firstName,
          lastName: lastName,
          lossDate: lossDateFormatted as string,
          lossTime: lossTimeFormatted as string,
          policyNumber,
          companyName: '',
        },
        data,
        isAuthedUser: true,
        logrocketurl: 'N/A',
        persona: CustomerTypes.SaCustomer,
      });
    }

    initializeContactList(data.data.draftClaimResponse, data.data.uuid);
    setUserAtomState({
      ...userAtomState,
      lossDate: lossDate,
      lossTime: lossTime,
      policyNumber: policyNumber?.replace(/\s+/g, ''),
      uuid: uuid,
      draftClaimResponse: data.data.draftClaimResponse,
      commercialAutoPage: true,
    });
    navigateDefaultReplace(LOB_PROPS[lob].formPath);
  };

  const catchForDraftClaim = () => {
    setContinueButtonDisabled(false);
    setPreviousButtonDisabled(false);
    setPolicySearchError(
      <span>
        An issue occurred with the claim setup process. Please contact our CARE team at{' '}
        <PhoneHyperlink />.
      </span>
    );
  };

  const onClickContinue = async () => {
    if (yesSelected === undefined) {
      setShowSelectedError(true);
      window.scrollTo(0, 0);
    } else {
      setContinueButtonDisabled(true);
      setPreviousButtonDisabled(true);
      const {lossDateFormatted, lossTimeFormatted, policyNumber, lossDate, lossTime, uuid} =
        dataForDraftClaim;
      try {
        const response = await draftClaim({
          lossDate: lossDateFormatted,
          lossTime: lossTimeFormatted,
          policyNumber,
          uuid,
          vins: [...checkedVehicleVinState],
        });
        responseForDraftClaim(
          response,
          policyNumber,
          lossDateFormatted,
          lossTimeFormatted,
          lossDate,
          lossTime,
          uuid
        );
      } catch {
        catchForDraftClaim();
      }
    }
  };

  const mappedTableData = tableData.map((e: TableData, index: number) => {
    return [{...e[0], index}, ...e.slice(1)];
  });

  useEffect(() => {
    setShowFilters(true);
  }, [showFilters]);

  const onResetSearch = () => {
    setTableData(originaltableData);
    setShowFilters(false);
    setFilters({make: [], model: []});
  };

  return (
    <>
      <SABox pt={'large'}>
        <SACard variant="standard" title="Select Listed Vehicles Involved">
          <SABox pb={'large'}>
            <SARadioGroup
              id="4Vehicles"
              label="Does this claim involve more than 4 vehicles listed on the policy?"
              onChange={e => {
                const value = e.currentTarget.value;
                setShowSelectedError(false);
                if (value === 'yes') {
                  setContinueButtonDisabled(true);
                  setYesSelected(true);
                } else {
                  setContinueButtonDisabled(false);
                  setYesSelected(false);
                }
              }}
              data-testid="radioGroup"
            >
              <SARadio id="yes-radio-fourVehicles" value="yes" label="yes" data-testid="radioYes" />
              <SARadio id="no-radio-fourVehicles" value="no" label="no" data-testid="radioNo" />
            </SARadioGroup>
            {showSelectedError && (
              <SAText colorVariant="alert">
                This question must be answered before being able to continue.
              </SAText>
            )}
          </SABox>

          {!yesSelected && (
            <>
              <SABox pb={'medium'}>
                <SAText>
                  Select all vehicles involved in the incident. If you are reporting a claim for a
                  vehicle not listed below, you may add it on the following page.
                </SAText>
              </SABox>

              {policySearchError && (
                <SABox>
                  <SAAlert severity="error">{policySearchError}</SAAlert>
                </SABox>
              )}

              <SABox sy="line">
                <AlignCenterBox pb={'small'} pt={'small'}>
                  <SARadioGroup
                    id="searchRadio"
                    label=""
                    variant="standard"
                    direction="horizontal"
                    value="ymmSearch"
                    onChange={e => {
                      const value = e.currentTarget.value;
                      if (value === 'vinSearch') {
                        setVinSearch(true);
                      } else {
                        setVinSearch(false);
                      }
                    }}
                    data-testid="searchRadioGroup"
                  >
                    <SARadio
                      value="vinSearch"
                      label="Search by VIN"
                      variant="standard"
                      data-testid="vinSearchRadio"
                    />
                    <SARadio
                      value="ymmSearch"
                      label="Search by Make Model"
                      variant="standard"
                      data-testid="ymmSearch"
                    />
                  </SARadioGroup>
                </AlignCenterBox>
                {showFilters && (
                  <>
                    {!vinSearch ? (
                      <>
                        <SAColumnCenter
                          columns={{xs: [12, 12, 12], md: [4, 4, 4]}}
                          spacing={{md: 'medium', sm: 'small', xs: 'small'}}
                        >
                          <SABox pb={{md: 'small'}} pt={'small'}>
                            <SASelect
                              name="vehicleMake"
                              inputRef={register}
                              label="Make"
                              id="Make"
                              multiple
                              clickToRemove
                              fullWidth
                              variant="checkbox"
                              data-testid="MakeOptions"
                              onChange={(event: any) => onChangeFilters(event, 'make')}
                            >
                              <span>Make</span>
                              {saSelectOptionsMake.map(make => {
                                return (
                                  <SASelectOption value={make.value}>{make.label}</SASelectOption>
                                );
                              })}
                            </SASelect>
                          </SABox>
                          <SABox pb={{md: 'small'}} pt={'small'}>
                            <SASelect
                              name="vehicleModel"
                              label="Model"
                              id="Model"
                              multiple
                              clickToRemove
                              fullWidth
                              variant="checkbox"
                              data-testid="ModelOptions"
                              onChange={(event: any) => onChangeFilters(event, 'model')}
                            >
                              <span>Model</span>
                              {saSelectOptionsModel.map(model => {
                                return (
                                  <SASelectOption value={model.value}>{model.label}</SASelectOption>
                                );
                              })}
                            </SASelect>
                          </SABox>
                          {/* <SABox pb={'small'} pt={'small'}>
                      name="vehicleYear"
                      label="Year"
                      id="Year"
                      multiple
                      clickToRemove
                      fullWidth
                      variant="checkbox"
                      data-testid="Year"
                      onChange={(event: any) => OnChange(event, 'year')}
                    >
                      <span>Year</span>
                      { {saSelectOptionsYear.map(year => {
                        return <SASelectOption value={year.value}>{year.label}</SASelectOption>;
                      })} }
                    </SASelect>
                  </SABox> */}
                        </SAColumnCenter>
                      </>
                    ) : (
                      <SASearch
                        fullWidth
                        id="searchbar"
                        placeholder="SEARCH FOR VIN"
                        onSearch={handleSearch}
                        variant="search"
                        label="vinSearch"
                        data-testid="searchForVin"
                      />
                    )}
                  </>
                )}
                <AlignCenterBox pb={'small'} pt={'small'}>
                  <SAButton
                    fullWidth={false}
                    label="RESET SEARCH"
                    onClick={onResetSearch}
                    textTransform="uppercase"
                    variant="secondary-medium"
                    data-testid="resetSearchButton"
                  />
                </AlignCenterBox>
              </SABox>
              <SABox pt={'large'}>
                <SATable columns={columns} data={mappedTableData} variant="table-to-listview" />
              </SABox>
            </>
          )}

          {showAlert && (
            <SABox sy="line" padding={'medium'}>
              <SAAlert severity="info" data-testid="errorMessage">
                If this claim involves more than four vehicles listed on the policy please call the
                State Auto CARE Team at 1-877-SA-Claim or 1-877-722-5246 for assistance submitting
                your claim.
              </SAAlert>
            </SABox>
          )}
          {!yesSelected && (
            <SABox sy="line" padding={'medium'}>
              <SAText type="standard" weight="bold" text="Vehicles Selected: " />
              {checkedVehicleState.map((checkedVehicle: VehicleState) => {
                // const year = checkedVehicle['year'] || '-';
                const make = checkedVehicle['make'] || '-';
                const model = checkedVehicle['model'] || '-';
                const vin = checkedVehicle['vIN'] || '-';
                // const tag = year + ' ' + make + ' ' + model + ' VIN: ' + vin.slice(-6);
                const tag = make + ' ' + model + ' VIN: ' + vin.slice(-6);
                return (
                  <SATag
                    variant="standard"
                    withBorder
                    closeable
                    onClose={(event: any) => handleClose(event, vin)}
                    label={tag}
                    key={vin}
                    data-testid={vin}
                  />
                );
              })}
            </SABox>
          )}
          <RighJustified pl={{md: 'xl'}}>
            <SAButton
              fullWidth={false}
              label="Previous"
              onClick={() => {
                navigate(-1);
              }}
              textTransform="uppercase"
              disabled={previousButtonDisabled}
              color="secondary"
              variant="link-small"
              data-testid={'previousButton'}
            />
            <SAButton
              fullWidth={false}
              label="Continue"
              onClick={onClickContinue}
              textTransform="uppercase"
              disabled={continueButtonDisabled}
              variant="primary-medium"
              data-testid={'continueButton'}
            />
          </RighJustified>
        </SACard>
      </SABox>
    </>
  );
};
