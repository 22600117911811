import {SelectProps} from '@material-ui/core';
import {SABox, SAButton, SAColumns, SAIcon, SAIcons, SAIconSize} from '@saux/design-system-react';
import React, {useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import styled from 'styled-components';
import {
  useContactListAtomState,
  useFormContactAtomState,
  useOpenOrCloseContactInfoModal,
  useManageContactOccurrences,
  useUserAtomState,
} from '../../../../atoms';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  ContactTypes,
  CustomerTypes,
} from '../../../../commonTypes';
import {useContactValidationSchemaContext, useVerifyPhone} from '../../../../hooks';
import {capitalizeFirstAndLastNames, hasSome, makePersonaCheck} from '../../../../utils/utils';
import {SASelect, SASelectProps} from '../../Select/Select';
import {ContactInfoModalSchema} from '../ContactInfoModal/ContactInfoModal';
import {
  ContactSections,
  CONTACT_EXCLUSION_RULES,
  FnolFormContact,
  FormContactPhone,
  CloseContactModalProps,
  FormContactSnapshot,
} from '../types';

const EditButtonContainer = styled(SABox)`
  @media (min-width: 600px) {
    padding-top: 3px;
  }
`;

const EditButton = styled(SAButton)`
  width: auto;
  height: 50px;
`;

export interface ContactSelectStateValue {
  id: string;
  value: string;
}

export interface AdditionalContactListOption {
  value: string;
  label: string;
  key: string;
}

export interface BaseContactInfoProps {
  selectProps: SelectProps & SASelectProps;
  defaultValue?: string;
  onContactChange?: (formContact: FnolFormContact, value?: string) => any;
  section: ContactSections;
  filterCriteria?: (contactOption: FormContactSnapshot) => boolean;
  additionalOptions?: AdditionalContactListOption[];
}

export interface ContactSelectProps extends BaseContactInfoProps {
  setFormContactId: React.Dispatch<React.SetStateAction<string>>;
  fieldNames: string[];
}

export const ContactSelect = ({
  selectProps,
  section,
  setFormContactId,
  filterCriteria,
  additionalOptions,
  defaultValue,
  onContactChange,
  fieldNames,
}: ContactSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<ContactSelectStateValue>({
    id: '',
    value: '',
  });
  const [formContactAtomState, setFormContactAtomState] = useFormContactAtomState(
    selectedValues.id
  );
  const [contactListAtomState] = useContactListAtomState();
  const {addContactOccurrences, removeContactOccurrences} = useManageContactOccurrences();
  const {openContactInfoModal} = useOpenOrCloseContactInfoModal();
  const {shouldVerifyPhone, verifyMobileNumber} = useVerifyPhone();
  const {clearErrors, register, trigger} = useFormContext();
  const afterInitialMount = useRef<boolean>(false);
  const selectedContactOccurrences =
    contactListAtomState.find((co: FormContactSnapshot) => co.value === selectedValues.id)
      ?.occurrences || [];
  const updatedOccurrences = selectedContactOccurrences.includes(section)
    ? [...selectedContactOccurrences]
    : [...selectedContactOccurrences, section];
  const {addressRequired, emailRequired, phoneRequired} =
    useContactValidationSchemaContext(updatedOccurrences);
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const lob = userAtomState?.gettingStarted?.lob || '';
  const exclusionRules = CONTACT_EXCLUSION_RULES[section];
  const filteredContacts = [...contactListAtomState].filter(
    (contactOption: FormContactSnapshot) => {
      return (
        // The currently selected contact should still appear as an option
        selectedValues.id === contactOption.value ||
        (!(
          hasSome({array: contactOption.occurrences, arrayToCheck: exclusionRules?.occurrences}) ||
          hasSome({array: contactOption.roles, arrayToCheck: exclusionRules?.roles?.[lob]}) ||
          contactOption.contactType === exclusionRules?.type
        ) &&
          // Any additional filter rules not present in the CONTACT_REQUIRED_FIELDS object
          (filterCriteria ? filterCriteria(contactOption) : true))
      );
    }
  );

  // Automatically defaulted contact
  const prefillContact =
    filteredContacts.find((contactOption: FormContactSnapshot) =>
      contactOption.prefillOccurrences?.includes(section)
    )?.value || '';

  const setContactIdAndSelectedValue = (value: string) => {
    let contactId: string = '';

    if (
      !(additionalOptions || [])
        .map((addOpt: AdditionalContactListOption) => addOpt.value)
        .includes(value)
    ) {
      contactId = value;
    }

    setFormContactId(contactId);
    setSelectedValues({id: contactId, value});
  };

  const onSave = ({modalContactId, isNewContact, isInvalidContact}: CloseContactModalProps) => {
    if (modalContactId) {
      if (isNewContact) {
        removeContactOccurrences([{id: selectedValues.id, sections: [section]}]);
        setContactIdAndSelectedValue(modalContactId);
      }

      if (isInvalidContact) {
        clearErrors(fieldNames);
      }
    }
  };

  const onEditClick = async (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const hasValidInfo = await ContactInfoModalSchema.isValid(
      {contact: {...formContactAtomState}},
      {
        context: {
          addressRequired,
          emailRequired,
          phoneRequired,
          isCompany: formContactAtomState.contactType === ContactTypes.Company,
        },
      }
    );

    openContactInfoModal({id: selectedValues.id, section, isInvalidContact: !hasValidInfo, onSave});
  };

  /**
   * Called when a contact is selected. Adds the current section where the contact is chosen to the corresponding
   * contact list entry and validates the available contact information.
   *
   * If the contact passes validation, any associated phone number will be verified if necessary.
   * If the contact fails validation, the select element will be shown in an error state with message.
   *
   * @param formContactValues - The information associated with the contact.
   */
  const validateContactAndUpdateOccurrences = (formContactValues: FnolFormContact) => {
    addContactOccurrences([{id: formContactValues.fnolId, sections: [section]}]);

    ContactInfoModalSchema.validate(
      {contact: {...formContactValues}},
      {
        context: {
          addressRequired,
          emailRequired,
          phoneRequired,
          isCompany: formContactValues.contactType === ContactTypes.Company,
        },
      }
    )
      .then((hasValidInfo: any) => {
        if (hasValidInfo) {
          const matchingIndex = formContactAtomState.phoneNumbers.findIndex(
            (phone: FormContactPhone) =>
              shouldVerifyPhone(phone.phoneNumber, phone.phoneType) &&
              phone.verifiedNumber !== 'true'
          );

          if (
            matchingIndex !== -1 &&
            !(updatedOccurrences.length > 1) &&
            formContactAtomState.policyInfo &&
            makePersonaCheck(
              userPersona as CustomerTypes,
              AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
              isAuthInsured,
              true
            )
          ) {
            verifyMobileNumber(formContactAtomState.phoneNumbers[matchingIndex].phoneNumber).then(
              (verified: boolean) => {
                setFormContactAtomState((prevState: FnolFormContact) => {
                  let phoneNumbers: FormContactPhone[] = [...prevState.phoneNumbers];
                  phoneNumbers.splice(matchingIndex, 1, {
                    ...phoneNumbers[matchingIndex],
                    verifiedNumber: verified ? 'true' : 'false',
                  });

                  return {...prevState, phoneNumbers};
                });
              }
            );
          }

          clearErrors(fieldNames);
        } else {
          trigger(fieldNames);
        }
      })
      .catch((error: any) => {
        trigger(fieldNames);
      });
  };

  const handleOnChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    _child: React.ReactNode
  ) => {
    if (e.target.value === 'other') {
      // Open modal when new contact selected
      openContactInfoModal({section, onSave});
    } else {
      // Remove this section from the occurrences for the previously selected contact
      removeContactOccurrences([{id: selectedValues.id, sections: [section]}]);
      setContactIdAndSelectedValue(e.target.value as string);
    }
  };

  useEffect(() => {
    // Skip effect if initial mount
    if (afterInitialMount.current) {
      // Side effects for after a contact is chosen
      onContactChange && onContactChange({...formContactAtomState}, selectedValues.value);

      if (selectedValues.id) {
        validateContactAndUpdateOccurrences(formContactAtomState);
      }
    }
  }, [formContactAtomState, selectedValues]);

  // Effect for automatic default value
  useEffect(() => {
    afterInitialMount.current = true;

    if (prefillContact) {
      setContactIdAndSelectedValue(prefillContact);
    }
  }, []);

  // Effect for manual default value
  useEffect(() => {
    if (defaultValue) {
      setContactIdAndSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <SAColumns columns={{xs: [12, 12], sm: [6, 6], md: [4, 8]}} spacing="medium">
      <SASelect
        {...selectProps}
        value={selectedValues.value}
        onChange={handleOnChange}
        inputRef={register()}
        inputProps={{...selectProps.inputProps, 'aria-labelledby': selectProps.id}}
      >
        {filteredContacts.map((contact: FormContactSnapshot, index: number) => {
          const isDuplicate =
            filteredContacts.findIndex(
              (c: FormContactSnapshot, i: number) =>
                c.label.toLocaleLowerCase() === contact.label.toLocaleLowerCase() && i !== index
            ) !== -1;

          const label = isDuplicate
            ? `${capitalizeFirstAndLastNames(contact.label)}${
                contact.phoneNumbers?.[0]?.phoneNumber
                  ? ' - ' + contact.phoneNumbers?.[0]?.phoneNumber
                  : contact?.email
                  ? ' - ' + contact?.email
                  : ''
              }`
            : capitalizeFirstAndLastNames(contact.label);

          return (
            <option value={contact.value} key={contact.value}>
              {label}
            </option>
          );
        })}
        {(additionalOptions || []).map((addOpt: AdditionalContactListOption) => (
          <option value={addOpt.value} key={addOpt.key}>
            {addOpt.label}
          </option>
        ))}
        <option value="other" key="addNewContact">
          Add new contact...
        </option>
      </SASelect>
      {selectedValues.id && (
        <EditButtonContainer>
          <EditButton
            label="EDIT CONTACT"
            variant="secondary-small-outline"
            startIcon={
              <SAIcon icon={SAIcons.pencil} colorVariant="primary" size={SAIconSize.small} />
            }
            onClick={onEditClick}
          />
        </EditButtonContainer>
      )}
    </SAColumns>
  );
};
