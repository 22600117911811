import React, {ReactElement} from 'react';
import {Box, Grid, Button, Hidden, Container, DialogProps} from '@material-ui/core';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {useForm} from 'react-hook-form/dist/index.ie11';
import {FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {SubmissionDialog} from '../SubmissionDialog/SubmissionDialog';
import {WaypointNames} from '../RegisterBreadcrumb/RegisterBreadcrumb';
import {StickyBreadcrumbs} from '../StickyBreadcrumbs/StickyBreadcrumbs';
import {trackEvent} from '../../../services/analytics';
import {ErrorDialog, ErrorDialogProps} from '../ErrorDialog/ErrorDialog';
import {SADialogProps} from '../SADialog/SADialog';
import {WithBreadcrumbsContext} from '../WithBreadcrumbContext/WithBreadcrumbContext';
import {AlertDialog} from '../AlertDialog/AlertDialog';
import {navigate} from '@reach/router';
import {ASSOCIATE_POLICY_SEARCH_ROUTE} from '../../../routes/paths';
import {CustomerTypes} from '../../../commonTypes';
import {Sidebar} from '../Sidebar/Sidebar';
import {UserPersonaSwitch} from '../UserPersonaSwitch/UserPersonaSwitch';

export const BlueContainer = withStyles(theme => ({
  root: {
    background: '#F0F3F8',
    padding: 21,
    marginLeft: -8,
    marginRight: -8,
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))(Box);

const useStyles = makeStyles(theme => ({
  submitBtn: {
    textAlign: 'end',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  main: {
    overflow: 'auto',
    paddingBottom: 121,
  },
  mainContent: {
    minHeight: '90vh',
    overflow: 'hidden',
    display: 'block',
    position: 'relative',
    paddingBottom: '121px',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  children: {
    paddingLeft: '200px',
    paddingRight: '200px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
}));

const CTAContainer = withStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    height: 121,
    borderTop: '1px solid #CFD0D4',
  },
}))(Box);

const CTAGrid = withStyles(theme => ({
  root: {
    height: 'inherit',
    paddingRight: 20,
    paddingLeft: 20,
    [theme.breakpoints.up('md')]: {
      paddingRight: 100,
    },
  },
}))(Grid);

export interface AppFormContainerProps {
  children: ReactElement;
  context?: object;
  validationSchema?: any;
  defaultValues?: any;
  lossDate?: string | null;
  policyNumber?: string | null;
  cancelControl?: (event: any) => void;
  submitControl?: any;
  submitDisabled?: boolean;
  submitButtonLabel?: string | null;
  isSubmitting: boolean | false;
  displaySubmitDialog?: boolean;
  displaySubmissionErrorDialog?: boolean;
  downloadLink?: string;
  waypoints?: WaypointNames[] | undefined;
  submissionEventTracking?: string | undefined;
  errorDialogProps?: DialogProps & SADialogProps & ErrorDialogProps;
  hideBreadcrumbs?: boolean;
  hideAddNoteButton?: boolean;
  hideCoveragesButton?: boolean;
}

export const AppFormContainer = (props: AppFormContainerProps) => {
  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: 'firstError',
    resolver: yupResolver(props.validationSchema),
    defaultValues: props.defaultValues,
    context: props.context,
  });

  const {handleSubmit, errors} = formMethods;
  const classes = useStyles();

  const trackFormSubmissionEvent = () => {
    if (props.submissionEventTracking) {
      trackEvent({
        category: 'Form Submission',
        label: props.submissionEventTracking as string,
      });
    }
  };

  const searchAgainHandler = (event: any) => {
    event.preventDefault();
    navigate(ASSOCIATE_POLICY_SEARCH_ROUTE);
  };

  const onClickHandler = () => {
    navigate(props.downloadLink || '');
  };

  return (
    <WithBreadcrumbsContext>
      <FormProvider {...formMethods}>
        {!props.hideBreadcrumbs && (
          <Hidden smDown>
            <UserPersonaSwitch
              ifPersonas={[CustomerTypes.Associate, CustomerTypes.SaAgent]}
              otherwise={<StickyBreadcrumbs breadcrumbs={props.waypoints} />}
            />
          </Hidden>
        )}
        <Container disableGutters>
          <form onSubmit={handleSubmit(props.submitControl)} autoComplete="off" noValidate>
            <Grid container className={classes.mainContent}>
              <UserPersonaSwitch
                ifPersonas={[CustomerTypes.Associate, CustomerTypes.SaAgent]}
                then={
                  <>
                    {!props.hideBreadcrumbs && (
                      <Hidden smDown>
                        <Sidebar
                          breadcrumbs={props.waypoints}
                          hideAddNoteButton={props?.hideAddNoteButton}
                          hideCoveragesButton={props?.hideCoveragesButton}
                        />
                      </Hidden>
                    )}
                    <Grid item xs={12} className={classes.children}>
                      {React.Children.only(props.children)}
                    </Grid>
                  </>
                }
                otherwise={
                  <Grid item xs={12}>
                    {React.Children.only(props.children)}
                  </Grid>
                }
              />

              <Grid item xs={12} className={classes.footer}>
                <CTAContainer>
                  <CTAGrid container justify="flex-end" alignItems="center">
                    <Grid item xs={12} md={3}>
                      <Box>
                        <Button
                          disabled={props.submitDisabled || props.isSubmitting}
                          className={classes.submitBtn}
                          fullWidth
                          disableElevation
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={trackFormSubmissionEvent}
                          data-testid="FormIsSubmitting"
                        >
                          {props.submitButtonLabel
                            ? props.submitButtonLabel
                            : 'SUBMIT CLAIM DETAILS'}
                        </Button>
                      </Box>
                    </Grid>
                  </CTAGrid>
                </CTAContainer>
              </Grid>
              {props.displaySubmitDialog && (
                <SubmissionDialog isSubmitting={props.displaySubmitDialog} />
              )}
              {props.displaySubmissionErrorDialog && (
                <AlertDialog
                  displayDialog={props.displaySubmissionErrorDialog}
                  searchAgainHandler={searchAgainHandler}
                  onClickHandler={onClickHandler}
                />
              )}
            </Grid>
            {props?.errorDialogProps?.open && <ErrorDialog {...props.errorDialogProps} />}
          </form>
        </Container>
      </FormProvider>
    </WithBreadcrumbsContext>
  );
};
