import html2pdf from 'html2pdf.js';

export class Pdf {
  private worker: any;
  private canvas: any;

  constructor(id: string, filename: string, isMobile?: boolean, setPdfDone?: any) {
    const commonOpt = {
      filename: filename,
      pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
    };
    const pageOpt = {
      filename: filename,
      pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
      html2canvas: {scale: 2},
    };

    if (isMobile) {
      const pages = Array.from(document.getElementsByClassName(id));
      const opt = {
        ...commonOpt,
        margin: [1, 1, 0, 1],
        html2canvas: {
          onrendered: this.setCanvas,
        },
      };

      this.worker = html2pdf()
        .set(opt)
        .from(pages[0])
        .toPdf()
        .then(() => {
          if (pages.length === 1) {
            // State setter passed in to indicate to component that all pages have been added
            setPdfDone(true);
          }
        });

      pages.slice(1).forEach((page: Element, index: number) => {
        this.worker = this.worker
          .get('pdf')
          .then((pdf: any) => {
            pdf.addPage();
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf()
          .then(() => {
            // Set canvas width and height to 0 to avoid running out of canvas memory
            const ctx = this.canvas.getContext('2d');
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvas.width = 0;
            this.canvas.height = 0;

            if (pages.slice(1).length - 1 === index) {
              // State setter passed in to indicate to component that all pages have been added
              setPdfDone(true);
            }
          })
          .catch((error: any) => {
            console.log('PDF Error:', error);

            if (pages.slice(1).length - 1 === index) {
              // State setter passed in to indicate to component that all pages have been added
              setPdfDone(true);
            }
          });
      });
    } else {
      const page = document.getElementById(id);

      if (page) {
        this.worker = html2pdf()
          .set(pageOpt)
          .from(page)
          .catch((error: any) => {
            console.log('PDF Error:', error);
          });
      }
    }
  }

  private setCanvas = (canvas: any) => {
    this.canvas = canvas;
  };

  generatePdfData(): Promise<any> {
    window.scrollTo(0, 0);
    return this.worker.output('blob');
  }

  savePdf() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.worker.save().catch((error: any) => {
        console.log('PDF Download Error:', error);
      });
    }, 1000);
  }
}
