import {debounce} from 'lodash';
import React, {useCallback, useRef, useState} from 'react';
import {hasSome} from '../../../utils/utils';
import {GooglePlacesContext} from '../Providers/Providers';
import {AddressComponent, NormalizedGoogleMapsData} from '../WithGoogleMaps/WithGoogleMaps';

export interface WithGooglePlacesContextProps {
  children: any;
}

export const WithGooglePlacesContext = (props: WithGooglePlacesContextProps) => {
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[] | undefined
  >(undefined);
  const [normalizedData, setNormalizedData] = useState<NormalizedGoogleMapsData>();
  const [placeLatLng, setPlaceLatLng] = useState<google.maps.LatLngLiteral | undefined>();

  const autocomplete = useRef(new window.google.maps.places.AutocompleteService());
  const placeDetails = useRef(
    new window.google.maps.places.PlacesService(document.createElement('div'))
  );

  const getPlacePredictions = (input: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    autocomplete?.current?.getPlacePredictions &&
      autocomplete?.current?.getPlacePredictions({input}, predictions => {
        const filteredPredictions =
          predictions?.filter((prediction: any) => prediction?.description?.includes('USA')) || [];
        setPredictions(filteredPredictions);
      });
  };

  const debouncedGetPlacePredictions = useCallback(debounce(getPlacePredictions, 500), []);

  const updatePlacePredictions = (input: any) => {
    debouncedGetPlacePredictions(input);
  };

  const updatePlaceDetails = (placeId: any) => {
    placeDetails.current.getDetails({placeId: placeId}, result => {
      setNormalizedData(normalizeTheData(result));
      setPlaceLatLng(result?.geometry?.location?.toJSON());
    });
  };

  // For manual fields not using Autocomplete service
  const getPlaceFromQuery = (input: string) => {
    placeDetails.current.findPlaceFromQuery(
      {query: input, fields: ['geometry.location']},
      (
        results: google.maps.places.PlaceResult[],
        status: google.maps.places.PlacesServiceStatus
      ) => {
        setPlaceLatLng(results?.[0]?.geometry?.location?.toJSON());
      }
    );
  };

  const normalizeTheData = (place: google.maps.places.PlaceResult): NormalizedGoogleMapsData => {
    const {address_components, geometry} = place;
    const streetNumber = address_components?.filter((ac: AddressComponent) =>
      ac.types.includes('street_number')
    )[0];
    const route = address_components?.filter((ac: AddressComponent) =>
      hasSome({array: ac.types, arrayToCheck: ['route', 'intersection']})
    )[0];
    const neighborhood = address_components?.filter((ac: AddressComponent) =>
      ac.types.includes('neighborhood')
    )[0];
    const city = address_components?.filter((ac: AddressComponent) =>
      ac.types.includes('locality')
    )[0];
    const state = address_components?.filter((ac: AddressComponent) =>
      ac.types.includes('administrative_area_level_1')
    )[0];
    const postalCode = address_components?.filter((ac: AddressComponent) =>
      ac.types.includes('postal_code')
    )[0];

    const normalizedData = {
      address: `${streetNumber?.long_name ? streetNumber.long_name + ' ' : ''}${
        route?.long_name || ''
      }`,
      city: city?.long_name || neighborhood?.long_name,
      state: state?.short_name,
      postalCode: postalCode?.long_name,
      latLng: geometry?.location?.toJSON(),
      intersecting: '',
    };

    return normalizedData;
  };

  return (
    <GooglePlacesContext.Provider
      value={{
        addressPredictions: predictions,
        updatePredictions: updatePlacePredictions,
        updatePlaceDetails: updatePlaceDetails,
        normalizedData: normalizedData,
        getPlaceFromQuery,
        placeLatLng,
      }}
    >
      {props.children}
    </GooglePlacesContext.Provider>
  );
};
