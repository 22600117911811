import React, {useEffect, useRef, useState} from 'react';
import {
  useContactListAtomState,
  useFormContactAtomState,
  useOpenOrCloseContactInfoModal,
  useUserAtomState,
} from '../../../../atoms';
import {
  AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
  ALL_PHONE_TYPES,
  ContactTypes,
  CustomerTypes,
  PhoneTypes,
} from '../../../../commonTypes';
import {yupResolver} from '@hookform/resolvers';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {
  SAAlert,
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAModal,
  SAText,
} from '@saux/design-system-react';
import styled from 'styled-components';
import {SATextField} from '../../TextField/TextField';
import {AddressSwitch} from '../../AddressSwitch/AddressSwitch';
import {PersonCompanyRadios} from '../../PersonCompanyRadios/PersonCompanyRadios';
import {
  getAddressSwitchProps,
  getMatchingPolicyContactByName,
  hasSome,
  makePersonaCheck,
  trimDataValues,
} from '../../../../utils/utils';
import {getStateBasedOnCode} from '../../StateDropdown/StateDropdown';
import {BaseSwitch} from '../../BaseSwitch/BaseSwitch';
import {useContactValidationSchemaContext} from '../../../../hooks';
import {
  MappedPhoneInfoByIdentifierProps,
  MultiplePhoneNumbers,
} from '../../MultiplePhoneNumbers/MultiplePhoneNumbers';
import {ContactInfoHeader} from '../components/ContactInfoHeader';
import {
  cityValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  OptionalPhoneNumbersSchema,
  phoneValidation,
  stateValidation,
  streetAddressValidation,
  zipCodeValidation,
} from '../../../../validations';
import {
  COMPANY_ONLY_SECTIONS,
  ContactInfoModalProps,
  FormContact,
  FormContactAutocompleteAddress,
  FormContactPhone,
  FormContactSnapshot,
  PERSON_ONLY_SECTIONS,
} from '../types';
import {SAEmail} from '../../SAEmail/SAEmail';
import {v4 as uuidv4} from 'uuid';
import {useFeatureFlags} from '../../Providers/Providers';

const ButtonBox = styled(SABox)`
  justify-content: flex-end;
`;
const CardContainer = styled(SABox)`
  max-width: 1000px;
  justify-content: center;
  height: max-content;
`;
const FlexModal = styled(SAModal as any)`
  display: flex;
  justify-content: center;
  overflow-x: auto;
  overflow-y: auto;
`;
const BlackTextButton = styled(SAButton)`
  color: black;
`;

export const phoneExtensionValidationRegex = /^\d{0,4}$/;

export const ContactInfoModalSchema = yup.object().shape({
  contact: yup.object().shape({
    fnolId: yup.string(),
    contactType: yup.string().nullable(),
    firstName: yup.string().when('contactType', {
      is: ContactTypes.Person,
      then: firstNameValidation().required('First Name is required'),
      otherwise: yup.string().nullable(),
    }),
    lastName: yup.string().when('contactType', {
      is: ContactTypes.Person,
      then: lastNameValidation().required('Last Name is required'),
      otherwise: yup.string().nullable(),
    }),
    companyName: yup.string().when('contactType', {
      is: ContactTypes.Company,
      then: yup.string().required('Company Name is required'),
      otherwise: yup.string().nullable(),
    }),
    email: yup.string().when('$emailRequired', {
      is: true,
      then: emailValidation(true).required('Email is required'),
      otherwise: emailValidation(true),
    }),
    contactAddress: yup.object().shape({
      autocompleteAddress: yup.string().when('manualAddressEntry', {
        is: (value: string) => !(value === 'manualAddress' || value === undefined),
        then: yup.string().when('$addressRequired', {
          is: true,
          then: yup.string().required('Address is required'),
          otherwise: yup.string(),
        }),
        otherwise: yup.string().nullable(),
      }),
      manualAddressEntry: yup.string().nullable(),
      address: yup.string().when('$addressRequired', {
        is: true,
        then: streetAddressValidation().required('Street Name/Address is required'),
        otherwise: streetAddressValidation(),
      }),
      city: yup.string().when('$addressRequired', {
        is: true,
        then: cityValidation(true).required('City is required'),
        otherwise: cityValidation(true),
      }),
      state: yup.string().when('$addressRequired', {
        is: true,
        then: stateValidation().required('State is required'),
        otherwise: stateValidation(),
      }),
      zipCode: yup.string().when('$addressRequired', {
        is: true,
        then: zipCodeValidation(true).required('ZIP Code is required'),
        otherwise: zipCodeValidation(true),
      }),
    }),
    phoneNumbers: yup.array().when('$phoneRequired', {
      is: true,
      then: yup.array().specifyIndeces(
        {
          0: {
            _schema: yup.object().shape({
              phoneNumber: yup.string(),
              phoneType: yup.string(),
              phoneExtension: yup.string(),
            }),
            phoneNumber: {
              _schema: phoneValidation(true).required('Phone Number is required'),
            },
            phoneType: {
              _schema: yup.string().when('$parentValue', {
                is: (parentValue: any) => parentValue?.phoneNumber,
                then: yup.string().required('Phone Type is required'),
                otherwise: yup.string(),
              }),
            },
            phoneExtension: {
              _schema: yup.string().when('$parentValue', {
                is: (parentValue: any) => !parentValue?.phoneNumber,
                then: yup
                  .string()
                  .nullable()
                  .test(
                    'empty-check',
                    'Phone extension should be empty if no phone number provided.',
                    value => !value
                  ),
                otherwise: yup
                  .string()
                  .test(
                    'is-valid-phone-extension',
                    'Phone extension must be a numeric value of up to 4 digits.',
                    val => {
                      if (val === undefined || val === null || val.trim() === '') return true;
                      return phoneExtensionValidationRegex.test(val);
                    }
                  ),
              }),
            },
          },
        },
        {
          _schema: yup
            .object()
            .shape({
              phoneNumber: yup.string(),
              phoneType: yup.string(),
              phoneExtension: yup.string(),
            })
            .nullable(),
          phoneNumber: {
            _schema: phoneValidation(true).nullable(),
          },
          phoneType: {
            _schema: yup.string().when('$parentValue', {
              is: (parentValue: any) => parentValue?.phoneNumber,
              then: yup.string().required('Phone Type is required'),
              otherwise: yup.string().nullable(),
            }),
          },
          phoneExtension: {
            _schema: yup.string().when('$parentValue', {
              is: (parentValue: any) => !parentValue?.phoneNumber,
              then: yup
                .string()
                .nullable()
                .test(
                  'empty-check',
                  'Phone extension should be empty if no phone number provided.',
                  value => !value
                ),
              otherwise: yup
                .string()
                .test(
                  'is-valid-phone-extension',
                  'Phone extension must be a numeric value of up to 4 digits.',
                  val => {
                    if (val === undefined || val === null || val.trim() === '') return true;
                    return phoneExtensionValidationRegex.test(val);
                  }
                ),
            }),
          },
        }
      ),
      otherwise: OptionalPhoneNumbersSchema.phoneNumbers,
    }),
  }),
});

interface ContactInfoForm {
  contact: FormContact<FormContactAutocompleteAddress>;
}

export const suffixTypes: {[key: string]: string} = {
  jr: 'Jr.',
  sr: 'Sr.',
  c_Ir: 'I',
  c_II: 'II',
  c_III: 'III',
  c_IV: 'IV',
  V: 'V',
  VI: 'VI',
  c_md: 'M.D.',
  c_phd: 'PhD.',
  c_do: 'D.O.',
  esq: 'Esq.',
};

const generateDuplicateContactMessage = (contactType: string) =>
  `A duplicate contact with a similar ${contactType} has been found. Please choose from the dropdown or update the existing information to continue.`;
export const duplicatePolicyContactMessage =
  'A contact with that name already exists. Please change the name to be able to save.';
export const duplicateContactMessage =
  'A contact with this name already exists. Please add more details, change the name, or select the existing contact from the dropdown to proceed.';
export const duplicatePhoneMessage = generateDuplicateContactMessage('Name and Phone number');
export const duplicateEmailMessage = generateDuplicateContactMessage('Name and Email');
export const duplicatePhoneEmailMessage = generateDuplicateContactMessage(
  'Name, Phone number and Email'
);

export const ContactInfoModal = ({
  id,
  section,
  onSave,
  onCancel,
  defaultIsCompany,
  isInvalidContact,
}: ContactInfoModalProps) => {
  const {featureFlags} = useFeatureFlags();
  const contactId = useRef<string>(id || `${uuidv4()}${Date.now()}`);
  const [afterInitialRender, setAfterInitialRender] = useState<boolean>(false);
  const [duplicateError, setDuplicateError] = useState<string>('');
  const {closeContactInfoModal} = useOpenOrCloseContactInfoModal();
  const [contactListAtomState, setContactListAtomState] = useContactListAtomState();
  const [formContactAtomState, setFormContactAtomState] = useFormContactAtomState(
    contactId.current
  );

  const [contactType, setContactType] = useState<ContactTypes>(
    formContactAtomState.contactType || ContactTypes.Person
  );
  const [userAtomState] = useUserAtomState();
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const isAuthedPersona = makePersonaCheck(
    userPersona as CustomerTypes,
    AGENT_AND_ASSOCIATE_AND_CUST_PERSONAS,
    isAuthInsured,
    true
  );
  const isAuthedPersonaAndPolicyContact = !!(formContactAtomState.policyInfo && isAuthedPersona);
  const draftClaimResponse = userAtomState?.draftClaimResponse?.result;
  const isNewContact = !contactListAtomState
    .map((co: FormContactSnapshot) => co.value)
    .includes(contactId.current);
  const selectedContactOccurrences =
    contactListAtomState.find((co: FormContactSnapshot) => co.value === contactId.current)
      ?.occurrences || [];
  const contactList = contactListAtomState.filter(
    (co: FormContactSnapshot) => co.value !== contactId.current
  );

  const occurrences = selectedContactOccurrences.includes(section)
    ? [...selectedContactOccurrences]
    : [...selectedContactOccurrences, section];
  const {addressRequired, emailRequired, phoneRequired} =
    useContactValidationSchemaContext(occurrences);
  const usedInCompanyOnlySection = hasSome({
    array: occurrences,
    arrayToCheck: COMPANY_ONLY_SECTIONS,
  });
  const usedInPersonOnlySection = hasSome({
    array: occurrences,
    arrayToCheck: PERSON_ONLY_SECTIONS,
  });
  const showPersonCompanyRadios = !(usedInCompanyOnlySection || usedInPersonOnlySection);
  const mandatoryPhoneFields = phoneRequired ? [0] : [];
  const defaultPhoneTypes = ALL_PHONE_TYPES.filter(
    (type: PhoneTypes) =>
      !formContactAtomState.phoneNumbers
        .map((phone: FormContactPhone) => phone.phoneType)
        .includes(type)
  );

  const defaultMappedPhoneValues = () => {
    let mappedValues: MappedPhoneInfoByIdentifierProps = {};

    formContactAtomState.phoneNumbers.forEach((phone: FormContactPhone, index: number) => {
      mappedValues[index] = {
        number: phone.phoneNumber,
        type: phone.phoneType,
        extension: phone.phoneExtension,
        verifiedMobile: phone.verifiedNumber === 'true',
      };
    });

    return mappedValues;
  };

  const formMethods = useForm<ContactInfoForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: 'firstError',
    resolver: yupResolver(ContactInfoModalSchema),
    context: {addressRequired, emailRequired, phoneRequired},
  });
  const {handleSubmit, errors, register, trigger} = formMethods;

  const {autocompleteAddressProps, manualAddressProps} = getAddressSwitchProps({
    parentFieldName: 'contact.contactAddress',
    errors: errors?.contact?.contactAddress,
    selectedState: getStateBasedOnCode(formContactAtomState.contactAddress.state),
    defaultValues: {
      address: formContactAtomState.contactAddress.address,
      city: formContactAtomState.contactAddress.city,
      zipCode: formContactAtomState.contactAddress.zipCode,
    },
    requiredStreetAddress: addressRequired,
    requiredCity: addressRequired,
    requiredState: addressRequired,
    requiredZipCode: addressRequired,
  });

  const handleRadioChange = (value: string) => {
    setContactType(value as ContactTypes);
  };

  const handleSaveClick = (_event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log('Contact modal errors:', errors);
  };

  const saveContact = (formData: ContactInfoForm) => {
    trimDataValues(formData);

    const label =
      formData.contact.companyName ||
      `${formData.contact.firstName} ${formData.contact.lastName} ${
        formData.contact.suffix ? '' + suffixTypes[formData.contact.suffix] : ''
      }`.trim();
    const duplicateContactName = contactList.find(
      (option: FormContactSnapshot) =>
        option.label.toLocaleLowerCase() === label.toLocaleLowerCase()
    );
    const duplicatePolicyContactName = contactList.find(
      (option: FormContactSnapshot) =>
        option.label.toLocaleLowerCase() === label.toLocaleLowerCase() &&
        option.contactType === formData.contact.contactType
    )?.policyInfo;
    const isDuplicatePhoneExist = !!contactList.find(
      (option: FormContactSnapshot) =>
        option.phoneNumbers &&
        option.phoneNumbers.some(
          phone =>
            phone.phoneNumber !== '' &&
            formData.contact.phoneNumbers.some(
              formPhone => formPhone.phoneNumber === phone.phoneNumber
            )
        )
    );
    const isDuplicateEmailExist = !!contactList.find(
      (option: FormContactSnapshot) =>
        option.email &&
        option.email !== '' &&
        option.email.toLocaleLowerCase() === formData.contact.email.toLocaleLowerCase()
    );

    // Check the duplicate fields
    const additionalContactInfo =
      formData.contact.email ||
      formData.contact.phoneNumbers[0].phoneNumber ||
      formData.contact.phoneNumbers[0].phoneExtension ||
      (formData.contact.contactAddress.address &&
        formData.contact.contactAddress.city &&
        formData.contact.contactAddress.state &&
        formData.contact.contactAddress.zipCode);
    const duplicateContactInfo =
      duplicateContactName?.email ||
      duplicateContactName?.phoneNumbers?.[0]?.phoneNumber ||
      duplicateContactName?.phoneNumbers?.[0]?.phoneExtension ||
      (duplicateContactName?.contactAddress?.address &&
        duplicateContactName?.contactAddress?.city &&
        duplicateContactName?.contactAddress?.state &&
        duplicateContactName?.contactAddress?.zipCode);

    const duplicateContact =
      (!additionalContactInfo || !duplicateContactInfo) && duplicateContactName;
    const isPhoneAndEmailDuplicate =
      duplicateContactName && isDuplicatePhoneExist && isDuplicateEmailExist;
    const isPhoneDuplicate = duplicateContactName && isDuplicatePhoneExist;
    const isEmailDuplicate = duplicateContactName && isDuplicateEmailExist;

    if (duplicatePolicyContactName) {
      // If matching name from the policy do not allow a duplicate
      setDuplicateError(duplicatePolicyContactMessage);
    } else if (duplicateContact) {
      // If duplicate contact name exists require more info and do not save or close the modal
      setDuplicateError(duplicateContactMessage);
    } else if (isPhoneAndEmailDuplicate || isPhoneDuplicate || isEmailDuplicate) {
      // If duplicate contact phone number and or email along with duplicate name exists do not save or close the modal
      setDuplicateError(
        isPhoneAndEmailDuplicate
          ? duplicatePhoneEmailMessage
          : isEmailDuplicate
          ? duplicateEmailMessage
          : duplicatePhoneMessage
      );
    } else {
      const matchingPolicyContact = isAuthedPersona
        ? formContactAtomState.policyInfo
        : getMatchingPolicyContactByName(draftClaimResponse, {
            firstName: formData.contact.firstName,
            lastName: formData.contact.lastName,
            companyName: formData.contact.companyName,
          });

      // Update contact information in atom
      setFormContactAtomState({
        fnolId: formData.contact.fnolId,
        contactType: formData.contact.contactType,
        firstName: formData.contact.firstName,
        lastName: formData.contact.lastName,
        suffix: formData.contact.suffix,
        companyName: formData.contact.companyName,
        email: formData.contact.email,
        phoneNumbers: formData.contact.phoneNumbers,
        contactAddress: {
          address: formData.contact.contactAddress.address,
          city: formData.contact.contactAddress.city,
          state: formData.contact.contactAddress.state,
          zipCode: formData.contact.contactAddress.zipCode,
        },
        policyInfo: matchingPolicyContact,
      });

      setContactListAtomState((list: FormContactSnapshot[]) => {
        let updatedList: FormContactSnapshot[] = [];

        if (isNewContact) {
          // Add new option to the contact list if new contact is being created
          updatedList = [
            ...list,
            {
              value: formData.contact.fnolId,
              label,
              contactType: formData.contact.contactType,
              roles: [],
              occurrences: [],
              firstName: formData.contact.firstName,
              lastName: formData.contact.lastName,
              suffix: formData.contact.suffix,
              companyName: formData.contact.companyName,
              policyInfo: matchingPolicyContact,
              email: formData.contact.email,
              phoneNumbers: formData.contact.phoneNumbers,
              contactAddress: {
                address: formData.contact.contactAddress.address,
                city: formData.contact.contactAddress.city,
                state: formData.contact.contactAddress.state,
                zipCode: formData.contact.contactAddress.zipCode,
              },
            },
          ];
        } else {
          // Update existing option in the contact list if values changed
          const changedOption = contactListAtomState.find(
            (option: FormContactSnapshot) =>
              option.value === formData.contact.fnolId &&
              (option.label !== label ||
                option.contactType !== formData.contact.contactType ||
                option.phoneNumbers !== formData.contact.phoneNumbers ||
                option.email !== formData.contact.email)
          );

          updatedList = changedOption
            ? [...list].map((co: FormContactSnapshot) =>
                co.value === formData.contact.fnolId
                  ? {
                      ...co,
                      label,
                      contactType: formData.contact.contactType,
                      firstName: formData.contact.firstName,
                      lastName: formData.contact.lastName,
                      suffix: formData.contact.suffix,
                      companyName: formData.contact.companyName,
                      policyInfo: matchingPolicyContact,
                      email: formData.contact.email,
                      phoneNumbers: formData.contact.phoneNumbers,
                      contactAddress: {
                        address: formData.contact.contactAddress.address,
                        city: formData.contact.contactAddress.city,
                        state: formData.contact.contactAddress.state,
                        zipCode: formData.contact.contactAddress.zipCode,
                      },
                    }
                  : {
                      ...co,
                    }
              )
            : list;
        }

        return updatedList;
      });

      // Logic to run when the contact is successfully saved
      onSave && onSave({modalContactId: contactId.current, isNewContact, isInvalidContact});
      closeContactInfoModal();
    }
  };

  const cancel = () => {
    // Logic to run when the contact creation/editing is canceled
    onCancel && onCancel({modalContactId: contactId.current, isNewContact, isInvalidContact});
    closeContactInfoModal();
  };

  useEffect(() => {
    if (usedInCompanyOnlySection || defaultIsCompany) {
      setContactType(ContactTypes.Company);
    }
  }, []);

  // Needed to ensure form values are updated before triggering validations
  useEffect(() => {
    setAfterInitialRender(true);
  }, []);

  // Trigger field validations upon opening modal if contact has invalid info
  useEffect(() => {
    if (isInvalidContact && afterInitialRender) {
      trigger();
    }
  }, [isInvalidContact, afterInitialRender]);

  // Required prop for SA design system modal
  const clickHandler = () => {};

  return (
    <FlexModal onClickHandler={clickHandler} zIndex={100}>
      <CardContainer p={{xs: 'small', sm: 'large', md: 'xl', xl: 'xxl'}}>
        <SACard variant="minimal">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(saveContact)} autoComplete="off" noValidate>
              <SABox pb="large">
                <SAText type="heading-3" text="Claim Contact" />
              </SABox>
              <SABox pb="medium">
                <ContactInfoHeader />
              </SABox>
              <input
                type="hidden"
                name="contact.fnolId"
                value={contactId.current}
                ref={register}
                data-testid="contactPublicID"
              />
              <SAColumns columns={[12, 12, 12, 12, 12]} spacing={{xs: 'small', md: 'medium'}}>
                <BaseSwitch
                  matches={showPersonCompanyRadios}
                  then={
                    <PersonCompanyRadios
                      name="contact.contactType"
                      defaultValue={contactType}
                      key={contactType}
                      onChange={handleRadioChange}
                      personTestId="contactModalPersonRadio"
                      companyTestId="contactModalCompanyRadio"
                    />
                  }
                  otherwise={
                    <input
                      type="hidden"
                      name="contact.contactType"
                      value={contactType}
                      ref={register}
                      data-testid="modalContactType"
                    />
                  }
                />
                <BaseSwitch
                  matches={contactType === ContactTypes.Company}
                  then={
                    <SAColumns columns={{xs: [12], sm: [6], md: [5]}}>
                      <SATextField
                        name="contact.companyName"
                        label="Company Name"
                        defaultValue={formContactAtomState.companyName || ''}
                        id="contactModalCompanyName"
                        InputLabelProps={{
                          'aria-labelledby': 'contactModalCompanyName',
                        }}
                        inputRef={register}
                        error={errors?.contact?.hasOwnProperty('companyName')}
                        helperText={errors?.contact?.companyName?.message}
                        characterLimit={30}
                        autoComplete="on"
                        autoFocus
                        disabled={isAuthedPersonaAndPolicyContact}
                      />
                      {isAuthedPersonaAndPolicyContact && (
                        <input
                          name="contact.companyName"
                          type="hidden"
                          ref={register}
                          value={formContactAtomState.companyName}
                          data-testid="contactModalHiddenCN"
                        />
                      )}
                    </SAColumns>
                  }
                  otherwise={
                    <SAColumns columns={{xs: [12, 12, 12], sm: [6, 6, 6], md: [5, 3, 4]}}>
                      <SABox mr={{xs: 'xs', sm: 'medium'}} mb={{xs: 'medium', sm: 'xxs'}}>
                        <SATextField
                          name="contact.firstName"
                          label="First Name"
                          defaultValue={formContactAtomState.firstName || ''}
                          id="contactModalFirstName"
                          InputLabelProps={{
                            'aria-labelledby': 'contactModalFirstName',
                          }}
                          inputRef={register}
                          error={errors?.contact?.hasOwnProperty('firstName')}
                          helperText={errors?.contact?.firstName?.message}
                          characterLimit={30}
                          autoComplete="on"
                          autoFocus
                          disabled={isAuthedPersonaAndPolicyContact}
                        />
                        {isAuthedPersonaAndPolicyContact && (
                          <input
                            name="contact.firstName"
                            type="hidden"
                            ref={register}
                            value={formContactAtomState.firstName}
                            data-testid="contactModalHiddenFN"
                          />
                        )}
                      </SABox>
                      <SABox mr={{xs: 'xs'}} mb={{xs: 'medium', sm: 'xxs'}}>
                        <SATextField
                          name="contact.lastName"
                          label="Last Name"
                          defaultValue={formContactAtomState.lastName || ''}
                          id="contactModalLastName"
                          InputLabelProps={{
                            'aria-labelledby': 'contactModalLastName',
                          }}
                          inputRef={register}
                          error={errors?.contact?.hasOwnProperty('lastName')}
                          helperText={errors?.contact?.lastName?.message}
                          characterLimit={30}
                          autoComplete="on"
                          disabled={isAuthedPersonaAndPolicyContact}
                        />
                        {isAuthedPersonaAndPolicyContact && (
                          <input
                            name="contact.lastName"
                            type="hidden"
                            ref={register}
                            value={formContactAtomState.lastName}
                            data-testid="contactModalHiddenLN"
                          />
                        )}
                      </SABox>
                      {featureFlags.FF_DCARE_6770 && (
                        <SABox mr={{xs: 'xs'}}>
                          <SATextField
                            name="contact.suffix"
                            label="Suffix"
                            defaultValue={formContactAtomState.suffix || ''}
                            select
                            SelectProps={{native: true}}
                            id="contactModalSuffix"
                            InputLabelProps={{
                              'aria-labelledby': 'contactModalSuffixName',
                            }}
                            inputRef={register}
                            error={errors?.contact?.hasOwnProperty('suffix')}
                            helperText={'optional'}
                            disabled={isAuthedPersonaAndPolicyContact}
                          >
                            <option aria-label="None" value="" />
                            {Object.keys(suffixTypes).map((key: string) => (
                              <option value={key}>{suffixTypes[key]}</option>
                            ))}
                          </SATextField>
                          {isAuthedPersonaAndPolicyContact && (
                            <input
                              name="contact.suffix"
                              type="hidden"
                              ref={register}
                              value={formContactAtomState.suffix}
                              data-testid="contactModalHiddenSFX"
                            />
                          )}
                        </SABox>
                      )}
                    </SAColumns>
                  }
                />
                <SAColumns columns={{md: [10]}}>
                  <MultiplePhoneNumbers
                    scope="contact"
                    id="contactModalPhone"
                    errorProperty={errors?.contact?.phoneNumbers}
                    mandatoryFields={mandatoryPhoneFields}
                    defaultMappedPhoneValues={defaultMappedPhoneValues()}
                    defaultPhoneTypes={defaultPhoneTypes}
                  />
                </SAColumns>
                <SAColumns columns={{xs: [12], sm: [6], md: [5]}}>
                  <SAEmail
                    name="contact.email"
                    label="Email"
                    defaultValue={formContactAtomState.email || ''}
                    id="contactModalEmail"
                    InputLabelProps={{
                      'aria-labelledby': 'contactModalEmail',
                    }}
                    inputRef={register}
                    error={errors?.contact?.hasOwnProperty('email')}
                    helperText={
                      errors?.contact?.email?.message || (emailRequired ? '' : 'optional')
                    }
                    characterLimit={30}
                    autoComplete="on"
                  />
                </SAColumns>
                <SAColumns columns={{md: [10]}}>
                  <AddressSwitch
                    autocompleteAddressProps={autocompleteAddressProps}
                    manualAddressProps={manualAddressProps}
                    parentFieldName="contact.contactAddress"
                    defaultManual={
                      !(
                        formContactAtomState.contactAddress.address === '' &&
                        formContactAtomState.contactAddress.city === '' &&
                        formContactAtomState.contactAddress.state === '' &&
                        formContactAtomState.contactAddress.zipCode === ''
                      )
                    }
                  />
                </SAColumns>
              </SAColumns>
              {duplicateError && (
                <SABox pt="medium">
                  <SAAlert severity="error" closeable onClose={() => setDuplicateError('')}>
                    {duplicateError}
                  </SAAlert>
                </SABox>
              )}
              <ButtonBox display={{xs: 'block', sm: 'flex'}} pt="large">
                <BlackTextButton
                  label="CANCEL"
                  variant="primary-link"
                  onClick={cancel}
                  fullwidth={false}
                  fullWidthUnder={600}
                />
                <SAButton
                  label="SAVE CONTACT"
                  variant="primary"
                  type="submit"
                  fullwidth={false}
                  fullWidthUnder={600}
                  onClick={handleSaveClick}
                />
              </ButtonBox>
            </form>
          </FormProvider>
        </SACard>
      </CardContainer>
    </FlexModal>
  );
};
