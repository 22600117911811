import React, {useState} from 'react';
import {Box, Container, Grid} from '@material-ui/core';
import {AppFormContainer} from '../../common/Containers/Containers';
import * as yup from 'yup';
import {formatISO, format} from 'date-fns';
import {OtherDetails, OtherDetailsSchema} from '../../auto/OtherDetails/OtherDetails';
import {trackEvent} from '../../../services/analytics';
import {trimDataValues, normalizeCheckboxData} from '../../../utils/utils';
import {
  convertFormData,
  propertySubmitClaim,
  uninhabitableSummaryCheck,
  updateDraftClaim,
} from '../../../services';
import {SubmissionTypes} from '../../common/Summary/types';
import {WithUserPersonaContext} from '../../common/WithUserPersonaContext/WithUserPersonaContext';
import {
  IncidentDetailsContainer,
  IncidentDetailsContainerSchema,
} from '../IncidentDetailsContainer/IncidentDetailsContainer';
import {
  CLAIM_ERROR_MESSAGE,
  ConditionalWaypoint,
  insertConditionalBreadcrumbs,
  navigateDefaultReplace,
  navigateToPolicySearchOnError,
  setDefaultGeneralDetailsData,
  setDefaultGettingStartedData,
  setParsedIncidentLocationLatLng,
  propertyDamageFormDefaultValues,
  getThirdPartyContacts,
  setInsuredMainContactIds,
  setAdditionalInjuredIds,
} from '../../../pages/utils';
import {AdditionalNotes, AdditionalNotesSchema} from '../../common/AdditionalNotes/AdditionalNotes';
import {OtherPropertyDetailsSchema} from '../OtherPropertyDetails/OtherPropertyDetails';
import {InjuredPersonDetailsSchema} from '../InjuredPersonDetails/InjuredPersonDetails';

import {WithDraftClaimContext} from '../../common/WithDraftClaimContext/WithDraftClaimContext';
import {WaypointNames} from '../../common/RegisterBreadcrumb/RegisterBreadcrumb';
import {
  InsuredContactInformation,
  InsuredContactInformationSchema,
} from '../InsuredContactInformation/InsuredContactInformation';
import {CustomerTypes} from '../../../commonTypes';
import {Snowplow} from '../../../pages/utils/snowplow';
import {BaseSwitch} from '../../common/BaseSwitch/BaseSwitch';
import {
  useContactListSnapshot,
  useResetAtomState,
  useResetUserAtomState,
  useUserAtomState,
} from '../../../atoms';
import Cookies from 'js-cookie';
import {mapData} from '../../../utils/dataMapper';
import {
  INCIDENT_NATURE_OF_LOSS_MAPPING,
  IncidentTypes,
  NatureOfLoss,
} from '../../common/Incidents/Incidents';
import {SUMMARY_PAGE_ROUTE} from '../../../routes/paths';
import {ClaimPreferences} from '../../common/ClaimPreferences/ClaimPreferences';
import {useFeatureFlags} from '../../common/Providers/Providers';
import {GettingStarted, GettingStartedSchema} from '../../auto/GettingStarted/GettingStarted';
import {WithGooglePlacesContext} from '../../common/WithGooglePlacesContext/WithGooglePlacesContext';
import {ContactInfoModalController} from '../../common/ContactInfo/ContactInfoModal/ContactInfoModalController';
import {GeneralDetails, GeneralDetailsSchema} from '../../common/GeneralDetails/GeneralDetails';

export const HomeownersFormValidationSchema = yup.object().shape({
  ...GeneralDetailsSchema,
  ...GettingStartedSchema,
  ...IncidentDetailsContainerSchema,
  ...AdditionalNotesSchema,
  ...OtherPropertyDetailsSchema,
  ...InjuredPersonDetailsSchema,
  ...InsuredContactInformationSchema,
  ...OtherDetailsSchema,
});

export const Homeowners = () => {
  const {featureFlags} = useFeatureFlags();
  const [userAtomState, _] = useUserAtomState();
  const isAuthInsured = userAtomState?.gettingStarted?.isAuthInsured;
  const [isDisabled] = useState(false);
  const [acknowledgement, setAcknowledgementForFormContext] = useState<boolean | undefined>();
  const [displaySubmitDialog, setDisplaySubmitDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [submitClaimErrorMessage, setSubmitClaimErrorMessage] = useState<null | JSX.Element>(null);
  const [displaySubmissionErrorDialog, setSubmissionErrorDialog] = useState(false);
  const [showClaimPreferencesWaypoint, setShowClaimPreferencesWaypoint] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [isLawsuitClaim, setIsLawsuitClaim] = useState(false);
  const lossDate = userAtomState?.lossDate;
  const lossTime = userAtomState?.lossTime;
  const lossTimezone = userAtomState?.lossTimezone;
  const policyNumber = userAtomState?.policyNumber;
  const uuid = userAtomState?.uuid;
  const userPersona = userAtomState?.gettingStarted?.customerType;
  const isAgent = userPersona === CustomerTypes.SaAgent;
  const [showInsuredContact, setShowInsuredContact] = useState<boolean>(isAgent);
  const isAssociate = userPersona === CustomerTypes.Associate;
  const draftClaimResponse = userAtomState?.draftClaimResponse?.result;
  const getContactList = useContactListSnapshot();
  const isAssociateOrAgent = isAssociate || isAgent;

  const resetUserAtomState = useResetUserAtomState();
  const resetAtomState = useResetAtomState();

  const onSubmit = async (data: any) => {
    const contactList = await getContactList();
    const thirdPartyList = getThirdPartyContacts(contactList);

    setIsSubmitting(true);
    setDisplaySubmitDialog(true);
    trackEvent({category: 'Homeowners Form', label: 'Property Submit Claim'});
    trimDataValues(data);

    const lossDateFormatted = lossDate && formatISO(new Date(lossDate));
    const lossTimeFormatted = lossTime && format(new Date(lossTime), 'hh:mm a');
    setDefaultGeneralDetailsData(data);
    setParsedIncidentLocationLatLng(data);
    setDefaultGettingStartedData(data, contactList);
    setInsuredMainContactIds(data.insuredContactInformation, contactList);

    data.gettingStarted.customerType = userPersona;
    data.generalDetails.lossTime = lossTimeFormatted;
    data.generalDetails.lossTimezone = lossTimezone;
    data.otherDetails.wereThereAnyWitnesses = data.otherDetails.wereThereAnyWitnesses || 'no';
    data.incidentDetails.incidents = normalizeCheckboxData(data.incidentDetails.incidents);

    propertyDamageFormDefaultValues(data, contactList);
    setAdditionalInjuredIds(data, contactList);

    if (data?.incidentDetails?.whereDamageToHomeIs) {
      data.incidentDetails.whereDamageToHomeIs = normalizeCheckboxData(
        data.incidentDetails.whereDamageToHomeIs
      );
    }

    if (data?.incidentDetails?.emergencyServices) {
      data.incidentDetails.emergencyServices = normalizeCheckboxData(
        data.incidentDetails.emergencyServices
      );
    }

    if (data?.incidentDetails?.whatWasDamagedToTheExteriorOfTheHome) {
      data.incidentDetails.whatWasDamagedToTheExteriorOfTheHome = normalizeCheckboxData(
        data.incidentDetails.whatWasDamagedToTheExteriorOfTheHome
      );
    }

    if (data?.incidentDetails?.whatWasDamagedToTheInteriorOfTheHome) {
      data.incidentDetails.whatWasDamagedToTheInteriorOfTheHome = normalizeCheckboxData(
        data.incidentDetails.whatWasDamagedToTheInteriorOfTheHome
      );
    }

    if (data?.incidentDetails?.whichRoomsWereDamagedOnTheFirstFloor) {
      data.incidentDetails.whichRoomsWereDamagedOnTheFirstFloor = normalizeCheckboxData(
        data.incidentDetails.whichRoomsWereDamagedOnTheFirstFloor
      );
    }

    if (data?.incidentDetails?.whichRoomsWereDamagedOnTheSecondFloor) {
      data.incidentDetails.whichRoomsWereDamagedOnTheSecondFloor = normalizeCheckboxData(
        data.incidentDetails.whichRoomsWereDamagedOnTheSecondFloor
      );
    }

    if (data?.incidentDetails?.whichRoomsWereDamagedInTheBasement) {
      data.incidentDetails.whichRoomsWereDamagedInTheBasement = normalizeCheckboxData(
        data.incidentDetails.whichRoomsWereDamagedInTheBasement
      );
    }

    if (data.incidentDetails?.propertyTypes) {
      data.incidentDetails.propertyTypes = normalizeCheckboxData(
        data.incidentDetails.propertyTypes
      );
    }

    if (
      data.incidentDetails &&
      data.incidentDetails.natureOfLoss ===
        INCIDENT_NATURE_OF_LOSS_MAPPING[IncidentTypes.YourHomeAndStructure][NatureOfLoss.Water]
    ) {
      data.incidentDetails.propertyTypes = ['{"label":"Home","value":"home"}'];
      data.incidentDetails.propertyTypes = normalizeCheckboxData(
        data.incidentDetails.propertyTypes
      );
    }

    const natureOfLossOrig = data.incidentDetails.natureOfLoss;

    if (data.incidentDetails?.lossCause) {
      data.incidentDetails.natureOfLoss = data.incidentDetails.lossCause;
    }

    if (data.incidentDetails?.wasDamageCausedByTreeOrLimb === '') {
      data.incidentDetails.wasDamageCausedByTreeOrLimb = "don't know";
    }

    if (data.incidentDetails?.isTreeLimbOnTheRoof === '') {
      data.incidentDetails.isTreeLimbOnTheRoof = "don't know";
    }

    if (data.incidentDetails?.haveTemporaryRepairsBeenMadeToTheRoof === '') {
      data.incidentDetails.haveTemporaryRepairsBeenMadeToTheRoof = "don't know";
    }

    if (data?.incidentDetails?.whichAreasHaveSmokeDamageOnly) {
      data.incidentDetails.whichAreasHaveSmokeDamageOnly = normalizeCheckboxData(
        data.incidentDetails.whichAreasHaveSmokeDamageOnly
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedMainLevelAreaSmokeDamageOnly) {
      data.incidentDetails.whichRoomsDamagedMainLevelAreaSmokeDamageOnly = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedMainLevelAreaSmokeDamageOnly
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly) {
      data.incidentDetails.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedUpperLevelAreaSmokeDamageOnly
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedBasementAreaSmokeDamageOnly) {
      data.incidentDetails.whichRoomsDamagedBasementAreaSmokeDamageOnly = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedBasementAreaSmokeDamageOnly
      );
    }

    if (data?.incidentDetails?.whichAreasWereDamagedByFire) {
      data.incidentDetails.whichAreasWereDamagedByFire = normalizeCheckboxData(
        data.incidentDetails.whichAreasWereDamagedByFire
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedMainLevelAreaDamagedByFire) {
      data.incidentDetails.whichRoomsDamagedMainLevelAreaDamagedByFire = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedMainLevelAreaDamagedByFire
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedUpperLevelAreaDamagedByFire) {
      data.incidentDetails.whichRoomsDamagedUpperLevelAreaDamagedByFire = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedUpperLevelAreaDamagedByFire
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedBasementAreaDamagedByFire) {
      data.incidentDetails.whichRoomsDamagedBasementAreaDamagedByFire = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedBasementAreaDamagedByFire
      );
    }

    if (data?.incidentDetails?.whichDamagedAreasWereWaterDamaged) {
      data.incidentDetails.whichDamagedAreasWereWaterDamaged = normalizeCheckboxData(
        data.incidentDetails.whichDamagedAreasWereWaterDamaged
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedMainLevelWaterDamage) {
      data.incidentDetails.whichRoomsDamagedMainLevelWaterDamage = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedMainLevelWaterDamage
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedUpperLevelWaterDamage) {
      data.incidentDetails.whichRoomsDamagedUpperLevelWaterDamage = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedUpperLevelWaterDamage
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedBasementWaterDamage) {
      data.incidentDetails.whichRoomsDamagedBasementWaterDamage = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedBasementWaterDamage
      );
    }

    if (data?.incidentDetails?.whichRoomsDamagedDetachedStructureWaterDamage) {
      data.incidentDetails.whichRoomsDamagedDetachedStructureWaterDamage = normalizeCheckboxData(
        data.incidentDetails.whichRoomsDamagedDetachedStructureWaterDamage
      );
    }
    const formData = data;
    let showUninhabitableSummary: any = undefined;
    const isNoticeOnly = !!formData.noticeOnly;

    try {
      if (thirdPartyList.length > 0 && featureFlags?.FF_DCX_2655) {
        await updateDraftClaim({
          publicID: draftClaimResponse.publicID,
          claimNumber: draftClaimResponse.claimNumber,
          isRetry: false,
          contacts: thirdPartyList,
        });
      }
    } catch (error) {
      setTimeout(async () => {
        try {
          await updateDraftClaim({
            publicID: draftClaimResponse.publicID,
            claimNumber: draftClaimResponse.claimNumber,
            isRetry: true,
            contacts: thirdPartyList,
          });
        } catch (error) {}
      }, 10000);
    } finally {
      propertySubmitClaim({
        ...data,
        lossDate: lossDateFormatted,
        policyNumber,
        uuid,
        isAuthInsured,
      })
        .then(async response => {
          const {data} = response;
          formData.incidentDetails.natureOfLoss = natureOfLossOrig;

          const associateCookieData = Cookies.get('associateCredentials');
          if (userPersona === CustomerTypes.Associate && associateCookieData) {
            const associateCredentials = JSON.parse(associateCookieData);
            Snowplow.track.submitClaimWithAssociateInfo({
              claimNumber: data.data.claimNumber,
              name: associateCredentials.name,
              email: associateCredentials.email,
              persona: userPersona,
              logrocketurl: 'N/A',
              epay: formData.claimsPreference?.isEpayOptOut ? 'Opt Out' : 'Opt In',
            });
            Snowplow.track.noticeOnlyClaim({
              claimNumber: data.data.claimNumber,
              name: associateCredentials.name,
              email: associateCredentials.email,
              persona: userPersona,
              logrocketurl: 'N/A',
              isNoticeOnly: isNoticeOnly,
            });
          } else {
            Snowplow.track.submitClaim({
              claimNumber: data.data.claimNumber,
              persona: userPersona,
              logrocketurl: 'N/A',
              epay: formData.claimsPreference?.isEpayOptOut ? 'Opt Out' : 'Opt In',
            });
          }

          try {
            const uninhabitableSummaryCheckResponse = await uninhabitableSummaryCheck({});
            showUninhabitableSummary = uninhabitableSummaryCheckResponse.data.data;
          } catch (error) {}

          navigateDefaultReplace(SUMMARY_PAGE_ROUTE, {
            state: mapData(
              {
                ...data.data,
                customerType: userPersona,
                showUninhabitableSummary: showUninhabitableSummary,
                submissionType: SubmissionTypes.Property,
              },
              {...formData, lossDate},
              userAtomState.draftClaimResponse
            ),
          });
        })
        .catch(error => {
          const associateCookieData = Cookies.get('associateCredentials');
          if (error?.response?.status === 530) {
            const updatedFormData = {...formData, lossDate};

            trackEvent({category: 'HO Claim Submission', label: 'timeout'});
            navigateDefaultReplace(SUMMARY_PAGE_ROUTE, {
              state: {
                ...mapData(
                  {
                    customerType: userPersona,
                    submissionType: SubmissionTypes.Property,
                    formData: updatedFormData,
                  },
                  updatedFormData,
                  userAtomState.draftClaimResponse
                ),
                timeoutOccurred: true,
              },
            });
          } else if (userPersona === CustomerTypes.Associate && associateCookieData) {
            convertFormData(
              {policyNumber, lossDate: lossDateFormatted, lossTime: lossTimeFormatted, ...formData},
              policyNumber || 'ENTERED_FORM_DATA'
            )
              .then(async response => {
                const {data} = response;
                setDisplaySubmitDialog(false);
                if (data.data) {
                  const downloadFileLink = typeof data.data !== 'boolean' ? data.data : '';
                  setDownloadLink(downloadFileLink);
                  setSubmissionErrorDialog(true);
                } else {
                  setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
                }
              })
              .catch(error => {
                setDisplaySubmitDialog(false);
                setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
              });
          } else {
            window.scrollTo(0, 0);
            setDisplaySubmitDialog(false);
            setSubmitClaimErrorMessage(CLAIM_ERROR_MESSAGE);
          }
        });
    }
  };

  const baseWaypoints = [
    WaypointNames.GettingStarted,
    WaypointNames.GeneralDetails,
    WaypointNames.IncidentDetails,
    WaypointNames.OtherDetails,
  ];

  const conditionalWaypoints: ConditionalWaypoint[] = [
    {
      condition: showInsuredContact,
      spliceStart: 1,
      waypoint: WaypointNames.InsuredContactInformation,
    },
    {
      condition: showClaimPreferencesWaypoint && !isLawsuitClaim,
      spliceStart: showInsuredContact ? 5 : 4,
      waypoint: WaypointNames.ClaimPreferences,
    },
    {
      condition: !isAssociateOrAgent,
      spliceStart: 'last',
      waypoint: WaypointNames.AdditionalNotes,
    },
  ];

  const waypoints = insertConditionalBreadcrumbs(baseWaypoints, conditionalWaypoints);

  return (
    <>
      <ContactInfoModalController />
      <AppFormContainer
        submitDisabled={isDisabled || !canContinue}
        submitControl={onSubmit}
        validationSchema={HomeownersFormValidationSchema}
        isSubmitting={isSubmitting}
        context={{
          userPersona,
          acknowledgement,
          lossDate,
          showInsuredContact,
          featureFlags,
          isAuthInsured,
        }}
        defaultValues={{
          generalDetails: {
            lossTime: null,
          },
        }}
        displaySubmitDialog={displaySubmitDialog}
        displaySubmissionErrorDialog={displaySubmissionErrorDialog}
        downloadLink={downloadLink}
        waypoints={waypoints}
        errorDialogProps={{
          open: !!submitClaimErrorMessage,
          header: 'Something went wrong.',
          cta: 'RETURN TO POLICY SEARCH',
          ctaOnClick: () => {
            resetAtomState();
            resetUserAtomState();
            navigateToPolicySearchOnError();
          },
          errorMsg: submitClaimErrorMessage || '',
        }}
        hideCoveragesButton={!isAssociate}
      >
        <WithUserPersonaContext userPersona={userPersona}>
          <WithDraftClaimContext draftClaimResponse={userAtomState.draftClaimResponse?.result}>
            <Grid container justify="center">
              <Container data-testid={`persona-${userPersona}`}>
                <Box mt={10}>
                  <Box pb={3}>
                    <GettingStarted setShowInsuredContact={setShowInsuredContact} />
                  </Box>
                  <BaseSwitch
                    matches={showInsuredContact}
                    then={
                      <Box pb={3}>
                        <InsuredContactInformation />
                      </Box>
                    }
                  />
                  <Box pb={3}>
                    <WithGooglePlacesContext>
                      <GeneralDetails
                        lossDate={(lossDate && new Date(lossDate)) || null}
                        lossTime={(lossTime && new Date(lossTime)) || null}
                        lossTimezone={lossTimezone}
                        setAcknowledgementForFormContext={setAcknowledgementForFormContext}
                        setCanContinue={setCanContinue}
                        setIsLawsuitClaim={setIsLawsuitClaim}
                      />
                    </WithGooglePlacesContext>
                  </Box>
                  {canContinue && (
                    <>
                      <IncidentDetailsContainer />
                      <Box pb={3}>
                        <OtherDetails />
                      </Box>
                      {!isLawsuitClaim && (
                        <Box pb={3}>
                          <ClaimPreferences
                            setShowClaimPreferencesWaypoint={setShowClaimPreferencesWaypoint}
                          />
                        </Box>
                      )}
                      <Box pb={3}>
                        <AdditionalNotes />
                      </Box>
                    </>
                  )}
                </Box>
              </Container>
            </Grid>
          </WithDraftClaimContext>
        </WithUserPersonaContext>
      </AppFormContainer>
    </>
  );
};
